/* ---- Row - nowrap  ---- */
.row-nowrap {
    flex-wrap: nowrap;

    &--xsm {
        @include screen($bp560) {
            flex-wrap: nowrap;
        }
    }

    &--sm {
        @include screen($bp768) {
            flex-wrap: nowrap;
        }
    }

    &--md {
        @include screen($bp992) {
            flex-wrap: nowrap;
        }
    }

    &--lg {
        @include screen($bp1200) {
            flex-wrap: nowrap;
        }
    }

    &--lg2 {
        @include screen($bp1400) {
            flex-wrap: nowrap;
        }
    }

    &--xlg {
        @include screen($bp1600) {
            flex-wrap: nowrap;
        }
    }
}
