.field-input .textbox,
.field-input select,
.field-element--fileselector .fs-preview-wrapper {
    @include form-textbox;
}

.field-element--small {
    .field-input .textbox,
    .field-input select,
    .field-element--fileselector .fs-preview-wrapper {
        @include form-textbox('small');
    }
}
