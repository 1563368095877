
.block-text-list {
    @include list-reset;

    &__item {
        border-top: 1px solid rgba(0,0,0,0.2);

        &:first-child {
            border-top: none;
        }

        &__link {
            display: block;
            text-decoration: none;
            padding: $spacing 0;
        }
    }
    small {
        color: $color-secondary;
    }
}
