
.children-gallery-list {
    @include list-reset;
    display: grid;
    grid-template-columns: 1fr;
    gap: $spacing*2;

    &--4 {
        grid-template-columns: repeat(4, 1fr);
    }
    @include screen($bp768) {
        &--2,
        &--3,
        &--4,
        &--5 {
            grid-template-columns: repeat(2, 1fr);
        }
    }
    @include screen($bp992) {
        gap: $spacing*3;

        &--3 {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    @include screen($bp1200) {
        &--3,
        &--5 {
            gap: $spacing*5;
            grid-template-columns: repeat(3, 1fr);
        }
        &--4 {
            grid-template-columns: repeat(4, 1fr);
        }
    }
    @include screen($bp1400) {

        gap: $spacing*5;

        &--5 {
            grid-template-columns: repeat(5, 1fr);
        }
    }

    &__item {
        &__link {
            display: block;
            color: inherit;
            text-decoration-color: transparent;
            transition:
                text-decoration-color $link-transition,
                color $link-transition;

            &:hover,
            &:focus,
            &:active {
                color: $color-primary;
                text-decoration-color: $color-primary;
            }
        }
        &__image {
            display: block;
            @include fallback-logo-image;

            @supports(aspect-ratio: 3/2) {
                aspect-ratio: 1/1;
            }
        }
        &__title {
            padding-top: $spacing;
        }
    }
}
