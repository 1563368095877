

.text-align-right {
    text-align: right;
}

.text-align-left {
    text-align: left;
}

.text-align-center {
    text-align: center;
}

@include screen($bp560) {
    .text-align-right--xsm {
        text-align: right;
    }

    .text-align-left--xsm {
        text-align: left;
    }

    .text-align-center--xsm {
        text-align: center;
    }
}

@include screen($bp768) {
    .text-align-right--sm {
        text-align: right;
    }

    .text-align-left--sm {
        text-align: left;
    }

    .text-align-center--sm {
        text-align: center;
    }
}

/* BP medium */
@include screen($bp992) {
    .text-align-right--md {
        text-align: right;
    }

    .text-align-left--md {
        text-align: left;
    }

    .text-align-center--md {
        text-align: center;
    }
}

/* BP large */
@include screen($bp1200) {
    .text-align-right--lg {
        text-align: right;
    }

    .text-align-left--lg {
        text-align: left;
    }

    .text-align-center--lg {
        text-align: center;
    }
}

