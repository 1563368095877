.expando {
    padding: 25px 45px;
    background-color: $color-grey-01;
    margin: 20px 0 30px;
}

/* - Expanded - */
.js .expando {
    &[aria-expanded="false"] {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    &[aria-expanded="true"] {
        clip: auto;
        height: auto;
        margin: 20px 0 30px;
        overflow: visible;
        position: relative;
        width: auto;
        padding: 25px 45px;
        animation: fadeInDown 200ms ease;
        animation-fill-mode: forwards;

        .expando-open__icon:before {
            transform: rotate(-45deg);
        }

        .expando-open__icon:after {
            transform: rotate(45deg);
        }
    }
}


.js .expando p:last-of-type {
    margin-bottom: 0;
}

/* - Open button - */
.expando-trigger {
    -webkit-appearance: none;
    border: none;
    outline: none;
    background: none;
    padding: 6px 0 3px 40px;
    font-family: $primary-font;
    color: $color-accent-01;
    position: relative;
    cursor: pointer;
    display: block;
    margin: 0 0 12px;
    font-size: inherit;
    line-height: inherit;
    transition: color $link-transition;

    /* Hover */
    &:hover,
    &:focus {
        color: darken($color-accent-01, 10%);
        text-decoration: underline;

        .expando-open__icon {
            background-color: $color-grey-02;
        }

        .expando-open__icon:before,
        .expando-open__icon:after {
            background-color: $color-grey-09;
        }
    }

    &[aria-pressed="true"] {
        outline: 1px dashed $color-grey-02;

        .expando-open__icon:before {
            transform: rotate(-45deg);
        }

        .expando-open__icon:after {
            transform: rotate(45deg);
        }
    }
}

.expando-open__icon {
    position: absolute;
    top: 50%;
    left: 0;
    background-color: $color-grey-01;
    width: 26px;
    height: 26px;
    transform: translate(0, -50%);

    &:before,
    &:after {
        content: " ";
        width: 14px;
        height: 2px;
        background-color: $color-grey-06;
        display: block;
        position: absolute;
        top: 12px;
        left: 0;
        right: 0;
        margin: 0 auto;
        transition:
            transform $link-transition,
            background-color $link-transition;
    }

    &:after {
        transform: rotate(90deg);
    }
}


/* - Close button - */
.expando__close {
    -webkit-appearance: none;
    border: none;
    outline: none;
    background: none;
    text-indent: -9999px;
    padding: 0;
    height: 26px;
    width: 26px;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;

    &:before,
    &:after {
        content: " ";
        width: 14px;
        height: 2px;
        background-color: $color-grey-06;
        display: block;
        position: absolute;
        top: 12px;
        left: 0;
        right: 0;
        margin: 0 auto;
        transition: transform 200ms ease;
    }

    &:before {
        transform: rotate(-45deg);
    }

    &:after {
        transform: rotate(45deg);
    }

    /* Hover */
    &:hover:before,
    &:hover:after {
        background-color: $color-grey-09;
    }
}


