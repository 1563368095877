
:root {
    --text-link-colour: #{$color-primary};
    --text-link-hover-colour: darken(#{$color-primary}, 20%);
}

a {
    color: var(--text-link-colour);
    transition:
        color $link-transition,
        opacity $link-transition;
}

a:hover,
a:active,
a:focus {
    color: var(--text-link-hover-colour);
}

/* Address `outline` inconsistency between Chrome and other browsers. */
a:focus {
    outline: thin dotted;
}

/* Improve readability when focused and also mouse hovered in all browsers. */
a:active,
a:hover {
    outline: 0;
}

/* Include file type and size in document links */
a.document::after {
    content: " (" attr(data-ext) " " attr(data-size) ")";
}

:target {
    animation: highlightPulse 700ms ease;
    outline: 3px solid $color-utility-selection;
    outline-offset: 4px;
}

.active-target {
    animation: highlightPulseOutline 2100ms ease;
}

.content-block {

    a[href$=".pdf"] {
        margin-right: $spacing*.5;

        &::after {
            content: " ";
            display: inline-block;
            width: 1.25em;
            height: 1.25em;
            vertical-align: middle;
            margin-left: .25em;
            margin-top: -.25em;
            background: url(../assets/icon-system/icon_pdf.svg) no-repeat center left;
            background-size: contain;
        }
    }
    a.button[href$=".pdf"] {

        &::after {
            margin-left: .12em;
            margin-right: -.25em;
            background-image: url(../assets/icon-system/icon_pdf--white.svg);
        }
    }
    // weird hack for weird redactor bug inserting empty links in list items
    .li > a[href$=".pdf"] + a[href$=".pdf"] {
        &::after {
            content: none;
        }
    }
}
