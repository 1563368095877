
$mobile-menu-transition: 350ms ease;


/*
* frankenMenu v1.0 // Karmabunny Web Design // built by Luke Underwood
*/


/** Desktop and Mobile styling
===================================*/
#frankenmenu-list,
#frankenmenu-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
}

#frankenmenu-list a {
    text-decoration: none;
    display: block;
}


/** Desktop menu only
===================================*/
@include screen($bpdesktop) {

    #frankenmenu {
        z-index: $header-index;
        width: 100%;
    }
    #mobile-header {
        display: none;
    }

    /* ---- All level menu items ---- */
    #frankenmenu-list {
        display: flex;

        .menu-item {
            position: relative;
        }
        .menu-item--desktop-hidden {
            display: none;
        }

        /* ---- First level menu ---- */
        .menu-item-depth1 {
            text-align: center;
            flex: 1 1 auto;
            border-right: 1px solid rgba($color-white, .45);

            &:first-child {
                border-left: 1px solid rgba($color-white, .45);
            }

            > a {
                display: block;
                color: $color-white;
                font-size: 110%;
                padding: 0.75em $spacing;
                font-weight: $fw-bold;
                transition: background $link-transition;
            }

            /* Hover */
            > a:hover,
            > a:focus,
            &.frankenhover > a,
            &.frankenhold > a {
                color: $color-white;
                background-color: $color-secondary;
            }
            /* Current item */
            &.menu-current-item > a,
            &.menu-current-item > a {
                background-color: $color-alternative;
                color: $color-white;
            }

            /* Ancestor item */
            > .sub-menu {
                background-color: darken($color-secondary, 3%);
                color: $color-white;
            }
        }

        /* ---- Submenus ---- */
        .sub-menu {
            display: none;
            min-width: 200px;
            position: absolute;
            box-shadow: 0 0 16px 1px rgba(0,0,0,0.4);
            z-index: 1;
            min-width: 250px;
            max-width: 350px;
            text-align: left;
            top: 0;
            left: 100%;

            a {
                border-top: 1px solid $color-grey-01;
                padding: 9px 18px 10px;
                color: $color-primary;
                font-size: 1.5rem;
                background-color: $color-white;
                transition:
                    background-color 200ms ease,
                    color 200ms ease;
            }

            .menu-item:first-child a {
                border-top: none;
            }

            .menu-item > a:hover,
            .menu-item > a:focus,
            .menu-item.frankenhover > a,
            .menu-item.frankenhold > a {
                background: $color-secondary;
                color: $color-white;
            }

            .frankenhold > & {
                display: block !important;
                opacity: 1 !important;
            }
        }

        /* ---- First level submenus ---- */
        .sub-menu-depth1 {
            top: 100%;
            left: auto;
        }

        /* ---- Right aligned submenus ---- */
        .sub-menu.submenu-right-align,
        .sub-menu.submenu-right-align .sub-menu {
            left: auto;
            right: 100%;
        }
        .sub-menu-depth1.submenu-right-align {
            left: auto;
            right: 0;
        }

        .menu-item-subnav {
            display: none;
        }

        /* ---- Megamenu ---- */
        .mega-menu {
            left: 0;
            right: 0;
            background-color: $color-white;
            text-align: left;
            top: 100%;
            position: absolute;
            border-bottom: 4px solid $color-grey-02;
            z-index: 51;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            width: 1px;
        }
        /* Remove relative positioning for mega menu */
        .frankenmenu-mega & .menu-item {
            position: static;
        }
        .menu-item.frankenhover .mega-menu,
        .menu-item.frankenhold .mega-menu {
            padding: 40px 0;
            width: auto;
            height: auto;
            clip: auto;
            overflow: visible;
            margin: auto;
        }
        .menu-item.frankenhold .mega-menu {
            display: block !important;
            height: auto !important;
        }

        /* Megamenu preview */
        .mega-menu-preview {
            float: left;
            color: $color-white;
            padding: 18px 20px;
            background-color: $color-grey-06;
            width: 22%;
            margin-right: 3%;
        }
        .mega-menu-preview-image {
            height: 120px;
            background: url(../images/mega-menu-image-fallback.jpg) no-repeat center center;
            background-size: cover;
        }
        .mega-menu-preview-text {
            margin-bottom: 0;
            line-height: 1.2;
        }

        /* Megamenu columns */
        .mega-menu .mega-menu-columns {
            float: left;
            width: 75%;
        }
        .mega-menu-column {
            width: 31.33333333%;
            float: left;
            margin-right: 3%;
        }
        .mega-menu-column:last-child {
            margin-right: 0;
        }

        /* Megamenu submenus */
        .mega-menu-submenu .menu-item {
            border-top: 1px solid $color-grey-02;
        }
        .mega-menu-submenu .menu-item:last-child {
            border-bottom: 1px solid $color-grey-02;
        }
        .mega-menu-submenu a {
            color: #000;
            padding: 5px 0;
        }
    }


    /* ---- Custom Sub-navigation ---- */

    #frankenmenu-subnav {
        float: right;
    }
    #frankenmenu-subnav-list,
    #frankenmenu-subnav-list ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    #frankenmenu-subnav-list {
        .menu-item-depth1 {
            display: inline-block;
        }
        .menu-item-depth1 > a {
            text-decoration: none;
            padding: 5px 5px;
            display: block;
            color: inherit;
        }
        .menu-item-depth1 > a:hover {
            text-decoration: underline;
        }
        .sub-menu {
            display: none;
        }
    }

    /* ---- Moved elements ---- */
    .frankenmoved {
        display: none;
    }



}

/** Mobile menu only
===================================*/
@include screen($bpmobile, 'max') {

    /* Prevents font-boosting of menu items on Android */
    #frankenmenu * {
        max-height: 999999px;
    }

    #wrap {
        margin-top: $header-height-mobile;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        background-color: $color-white;
    }

    #frankenmenu {
        top: 0;
        position: fixed;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100%;
        background-color: $color-white;
        width: 70%;
        z-index: 0;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        padding-top: $header-height-mobile;
        border-left: 1px solid $color-grey-01;
        z-index: $header-index - 1;

        .container {
            position: relative;
            padding: 0;
            max-width: none;
            width: 100%;
            margin: 0;
            opacity: 0;
        }
    }


    /* ---- Mobile header ---- */
    #mobile-header {
        background-color: $color-white;
        height: $header-height-mobile;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: $header-index;
        border: 1px solid $color-grey-01;
        transition:
            background-color $mobile-menu-transition;

        .container {
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    #mobile-menu-button {
        -webkit-appearance: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        outline: none;
        border: none;
        padding: 0;
        text-indent: -9999px;
        width: 40px;
        height: 36px;
        margin-top: -2px;
        position: relative;
        background: none;
    }

    .mobile-menu-button-top,
    .mobile-menu-button-middle,
    .mobile-menu-button-bottom {
        height: 3px;
        background-color: $color-grey-08;
        left: 6px;
        animation-fill-mode: forwards;
        animation-duration: 400ms;
        position: absolute;
        display: block;
        right: 6px;
    }
    .mobile-menu-button-top {
        top: 8px;
    }
    .mobile-menu-button-middle {
        top: 17px;
    }
    .mobile-menu-button-bottom {
        top: 26px;
    }

    /* Animations active */
    .frankenmenu-mob-menu-animations .mobile-menu-button-top {
        animation-name: mobileMenuBtnTopClose;
    }
    .frankenmenu-mob-menu-animations .mobile-menu-button-middle {
        animation-name: mobileMenuBtnMiddleClose;
    }
    .frankenmenu-mob-menu-animations .mobile-menu-button-bottom {
        animation-name: mobileMenuBtnBottomClose;
    }

    /* Close button */
    .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-top {
        animation-name: mobileMenuBtnTopOpen;
    }
    .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-middle {
        animation-name: mobileMenuBtnMiddleOpen;
    }
    .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-bottom {
        animation-name: mobileMenuBtnBottomOpen;
    }

    @keyframes mobileMenuBtnTopClose {
        0% {
            transform: translate(0, 9px) rotate(45deg);
        }
        50%, 70% {
            transform: translate(0, 9px);
        }
        100% {
            transform: translate(0, 0);
        }
    }
    @keyframes mobileMenuBtnTopOpen {
        0% {
            transform: translate(0, 0);
        }
        50%, 70% {
            transform: translate(0, 9px);
        }
        100% {
            transform: translate(0, 9px) rotate(45deg);
        }
    }
    @keyframes mobileMenuBtnBottomClose {
        0% {
            transform: translate(0, -9px) rotate(-45deg);
        }
        50%, 70% {
            transform: translate(0, -9px);
        }
        100% {
            transform: translate(0, 0);
        }
    }
    @keyframes mobileMenuBtnBottomOpen {
        0% {
            transform: translate(0, 0);
        }
        50%, 70% {
            transform: translate(0, -9px);
        }
        100% {
            transform: translate(0, -9px) rotate(-45deg);
        }
    }
    @keyframes mobileMenuBtnMiddleClose {
        0% {
            opacity: 0;
        }
        50%, 70%, 100% {
            opacity: 1;
        }
    }
    @keyframes mobileMenuBtnMiddleOpen {
        0% {
            opacity: 1;
        }
        50%, 70%, 100% {
            opacity: 0;
        }
    }


    /* ---- All level menu items ---- */
    #frankenmenu-list,
    #frankenmenu-list ul {
        width: 100%;
    }
    #frankenmenu-list {
        .menu-item {
            position: relative;
            border-bottom: 1px solid $color-grey-01;
            float: none;
            width: 100%;
        }
        a {
            min-height: 40px;
            width: 100%;
            padding: 9px 64px 9px 16px;
            color: $color-grey-06;
        }
        .menu-item.menu-current-item > a {
            background-color: $color-primary;
            color: $color-white;
        }
        .menu-item--member-login a {
            background-color: $color-secondary !important;
            color: $color-white;
            font-weight: bold;
        }
        /* ---- First level menu ---- */

        ul > .menu-item:last-child {
            border-bottom: none;
        }
        /* ---- Submenus ---- */
        .sub-menu {
            display: none;
        }
        .menu-current-item-ancestor > .sub-menu {
            display: block;
        }

        /* Depth differences */
        .menu-item-depth1 > a {
            padding-left: 16px;
            background-color: $color-white;
        }
        .menu-item-depth2 > a  {
            padding-left: 24px;
        }
        .menu-item-depth3 > a  {
            padding-left: 32px;
        }
        .menu-item-depth4 > a  {
            padding-left: 40px;
        }
        .menu-item-depth5 > a  {
            padding-left: 48px;
        }
        .menu-item-depth6 > a  {
            padding-left: 56px;
        }
    }


    /* Expand buttons */
    .submenu-toggle {
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none;
        border: none;
        width: 56px;
        background: none;
        height: 43px;
        display: block;
        padding: 0;
        margin: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;
        padding: 0;
    }
    .submenu-toggle:before,
    .submenu-toggle:after {
        content: " ";
        width: 12px;
        height: 3px;
        display: block;
        position: absolute;
        background-color: $color-grey-06;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 22px;
        transition:
            top 400ms ease,
            transform 400ms ease;
    }
    .submenu-toggle:before {
        transform: rotate(45deg) translateX(-5px);
    }
    .submenu-toggle:after {
        transform: rotate(-45deg) translateX(5px);
    }
    .menu-item-submenu-open > .submenu-toggle:before,
    .menu-item-submenu-open > .submenu-toggle:after {
        top: 15px;
    }
    .menu-item-submenu-open > .submenu-toggle:before {
        transform: rotate(-45deg) translateX(-5px);
    }
    .menu-item-submenu-open > .submenu-toggle:after {
        transform: rotate(45deg) translateX(5px);
    }
    .menu-item.menu-current-item .submenu-toggle:before,
    .menu-item.menu-current-item .submenu-toggle:after {
        background-color: $color-white;
    }


    /* ---- Sub-navigation ---- */
    #frankenmenu-subnav-list {
        display: none;
    }

    /* ---- Menu positions ---- */

    /* Left */
    .frankenmenu-mobile-pos-left #frankenmenu {
        left: -70%;
    }
    .frankenmenu-mobile-pos-left.frankenmenu-mob-menu-visible #frankenmenu {
        left: 0;
    }
    .frankenmenu-mobile-pos-left #frankenmenu .container {
        transform: translate3d(-10%, 0, 0) scale(0.9);
    }
    .frankenmenu-mobile-pos-left.frankenmenu-mob-menu-visible #wrap {
        transform: translate3d(70%, 0, 0);
    }

    /* Right */
    .frankenmenu-mobile-pos-right #frankenmenu {
        right: -70%;
    }
    .frankenmenu-mobile-pos-right.frankenmenu-mob-menu-visible #frankenmenu {
        right: 0;
    }
    .frankenmenu-mobile-pos-right #frankenmenu .container {
        transform: translate3d(10%, 0, 0) scale(0.9);
    }
    .frankenmenu-mobile-pos-right.frankenmenu-mob-menu-visible #wrap {
        transform: translate3d(-70%, 0, 0);
    }
    .frankenmenu-mobile-pos-right #mobile-menu-button {
        float: right;
    }

    /* ---- Transitions ---- */
    #frankenmenu {
        transition:
            left $mobile-menu-transition,
            right $mobile-menu-transition,
            opacity $mobile-menu-transition;
    }
    #wrap {
        transition:
            transform $mobile-menu-transition;
    }
    #frankenmenu .container {
        transition:
            transform $mobile-menu-transition,
            opacity $mobile-menu-transition;
    }
    .js #frankenmenu {
        opacity: 0;
    }

    /* Menu open */
    .frankenmenu-mob-menu-visible #frankenmenu {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
    .frankenmenu-mob-menu-visible #frankenmenu .container {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(1);
    }

    /* ---- Moved elements ---- */
    .frankenmove {
        display: none;
    }

    /* ---- Megamenu ---- */
    .mega-menu-preview {
        display: none;
    }
    .mega-menu-preview-image {
        background-image: none !important;
    }
    .mega-menu-column-title {
        color: $color-white;
        font-size: $fs-body;
        padding: 3px 20px;
        margin: 0;
        background: rgba(0,0,0,0.3);
    }
    #frankenmenu-list .mega-menu {
        display: none;
    }
    #frankenmenu-list .mega-menu ul {
        display: block;
    }

}
