
.site-messages {
    list-style: none;
    margin: 0;
    padding: 0;

    &__item {
        display: block;
        margin: 0 0 15px;
        padding: 10px 20px;
        vertical-align: bottom;
        text-align: left;
        font-size: 16px;
        font-size: 1.6rem;
        line-height: 1.2em;

        &:last-child {
            margin-bottom: 0;
        }

        &.site-messages__item--mb {
            margin-bottom: $fs-body;
        }
    }
}

.site-messages .error {
    @include error;
}

.site-messages .confirm {
    @include positive;
}

.site-messages .warning {
    @include warning;
}

.site-messages a {
    color: $color-white;
}
