blockquote {
    margin: var(--body-font-size) 0;
    padding: 20px 25px 20px;
    background: $color-grey-01;
    font-style: italic;
    position: relative;
    clear: both;
}

blockquote *:first-child {
    margin-top: 0;
}

blockquote *:last-child {
    margin-bottom: 0;
}

blockquote:before,
blockquote:after {
    position: absolute;
    font-size: 13rem;
    display: block;
    height: 25px;
    width: 50px;
    color: $color-grey-01;
    font-style: italic;
    line-height: 10px;
    font-family: "Big Caslon","Book Antiqua","Palatino Linotype",Georgia,serif;
}

blockquote:before {
    content: '\201C';
    top: 38px;
    left: -8px;
}

blockquote:after {
    content: '\201D';
    bottom: -36px;
    right: 18px;
}

@include screen($bp992) {
    .blockquote--left {
        float: left;
        width: 45%;
        margin-left: 0px;
        margin-right: 20px;
        clear: right;
    }

    .blockquote--right {
        float: right;
        width: 45%;
        margin-left: 20px;
        margin-right: 0px;
        clear: left;
    }
}
