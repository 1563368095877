* {
    margin: 0;
}

html,
body {
    background: #FFF;
    height: 100%;
    scroll-behavior: smooth;
}

html {
    font-size: 62.5%;
}

body {
    line-height: 1.5;
    font-size: $fs-body;
    color: $color-body-font;
    font-family: $primary-font;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


#content {
    background-color: $color-white;
}
