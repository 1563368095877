.field-element {
    margin-bottom: $fs-body;
}

.field-element--mb0 {
    margin-bottom: 0;
}

/* ---- Placeholders ---- */
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $color-utility-placeholder;
}

::-moz-placeholder { /* Firefox 19+ */
    color: $color-utility-placeholder;
}

:-ms-input-placeholder { /* IE 10+ */
    color: $color-utility-placeholder;
}

:-moz-placeholder { /* Firefox 18- */
    color: $color-utility-placeholder;
}

.field-element--dropdown--placeholder .field-input select {
    color: $color-utility-placeholder;
}

/* ---- Auto-fill color ---- */
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
	background-color: $color-grey-02;
	-webkit-text-fill-color: #000;
	-webkit-box-shadow: 0 0 0px 1000px $color-grey-02 inset;
}

input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  outline: $color-grey-06 auto 5px;
  background-color: $color-grey-02;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 80px $color-grey-02 inset;
  transition: background-color 5000s ease-in-out 0s;
}

 /* All form elements with preceding icon */
 .field-element--datepicker .field-input .textbox,
 .field-element--daterangepicker .field-input .textbox,
 .field-element--datetimepicker .field-input .textbox,
 .field-element--datetimerangepicker .field-input .textbox,
 .field-element--money .field-input .textbox,
 .field-element--timepicker .field-input .textbox {
     padding-left: $form-spacing*6.5;
 }

 /* Clearfix all field-elements */
.field-element:before,
.field-element:after {
    content: " ";
    display: table;
}

.field-element:after {
    clear: both;
}

/* ---- form colour variants ---- */
/* -- default - grey -- */
.field-input input,
.field-input textarea,
.field-input select {
    @include form-textbox-styles;
    border-radius: $standard-radius;
}

.field-input input:focus,
.field-input textarea:focus,
.field-input select:focus {
    outline: $color-grey-06 auto 5px;
}

/* -- white -- */
.field-element--white .field-input input,
.field-element--white .field-input textarea,
.field-element--white .field-input select {
    background-color: $color-white;
}

/* ---- Disabled ---- */
.field-element--disabled,
.fieldset-input.fieldset-input--disabled {
    opacity: 0.6;
    cursor: not-allowed;
}
.field-element--disabled .textbox[disabled],
.field-element--disabled select[disabled],
.field-element--disabled button[disabled],
.field-element--disabled input[type="checkbox"] + label,
.fieldset-input.fieldset-input--disabled input[type="checkbox"] + label,
.field-element--disabled input[type="radio"] + label,
.fieldset-input.fieldset-input--disabled input[type="radio"] + label {
    cursor: not-allowed;
}

/* ---- Labels ---- */
/* ---- Labels ---- */
.field-label,
.fieldset__legend {
    @include field-label;
}

.field-element--small {
    .field-label,
    .fieldset__legend {
        font-size: var(--centi-font-size);
    }
}

.field-label.field-label--xsm {
    font-size: var(--milli-font-size);
}

/* Hidden labels */
.field-element--hidden-label .field-label,
.field-element--hidden-label .fieldset__legend {
    @include vis-hidden;
}

/* ---- Required ---- */
.field-label__required {
    text-indent: -9999px;
    line-height: 0;
    display: inline-block;
}

.field-label__required:after {
    content: "*";
    text-indent: 0;
    display: block;
    line-height: inherit;
}

/* ---- Field helpers ---- */
.field-helper {
    font-weight: 400;
    font-size: $fs-small;
    margin-bottom: 1em;
}

.field-label .field-helper {
    margin-top: 0.3em;
    margin-bottom: 0;
}


/* ---- form style extenders ---- */
%form-field-pseudo-anchor--before {
    position: absolute;
    top: -$form-spacing;
    left: $form-spacing*2;
    display: inline-block;
    border-right: $form-spacing solid transparent;
    border-bottom: $form-spacing solid $color-grey-04;
    border-left: $form-spacing solid transparent;
    border-bottom-color: $color-grey-04;
    content: '';
}
%form-field-pseudo-anchor--after {
    position: absolute;
    top: -$form-spacing + 1;
    left: $form-spacing * 2 + 1;
    display: inline-block;
    border-right: $form-spacing - 1 solid transparent;
    border-bottom: $form-spacing - 1 solid #fff;
    border-left: $form-spacing - 1 solid transparent;
    content: '';
}
