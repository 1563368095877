:root {
    --input-diameter: 1.5em;
    --input-small-diameter: 1em;
}

/* fieldset styles */
fieldset {
   border: none;
   padding: 0;
   margin: 0;
}

.field-element--fieldset-columns .field-element__input-set {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    column-gap: $spacing*2;
}

/* Hide checkbox and radio field element) */
.field-element input[type="checkbox"],
.field-element input[type="radio"] {
    @include vis-hidden;
}

.field-element input[type="checkbox"] + label,
.field-element input[type="radio"] + label,
.field-element--small input[type="checkbox"] + label,
.field-element--small input[type="radio"] + label {
    @include fieldsetLabel;
}


/* -- Field set pseudo elements -- */
.field-element input[type="checkbox"] + label:before,
.field-element input[type="radio"] + label:before {
    content: " ";
    @include fieldsetInput;
    top: 0.15em;
    margin-left: -$form-spacing*4;
}

.field-element--small input[type="checkbox"] + label:before,
.field-element--small input[type="radio"] + label:before {
    width: var(--input-small-diameter);
    height: var(--input-small-diameter);
}

/* Radio button (pseudo element) */
.field-element input[type="radio"] + label:before,
.field-element--small input[type="radio"] + label:before {
    border-radius: 100%;
}

/* -- Field set pseudo elements : checked -- */
.field-element input[type="checkbox"]:checked + label:after,
.field-element input[type="radio"]:checked + label:after,
.field-element--small input[type="checkbox"]:checked + label:after,
.field-element--small input[type="radio"]:checked + label:after {
    content: " ";
    position: absolute;
    left: 1px;
    top: 0.6em;
}

/* Checkbox box checked (pseudo element) */
.field-element input[type="checkbox"]:checked + label:after {
    content: " ";
    @include fieldsetInputCheckboxChecked;
}

.field-element--small input[type="checkbox"]:checked + label:after {
    width: var(--input-small-diameter);
    height: var(--input-small-diameter);
}

/* Radio dot (pseudo element) */
.field-element input[type="radio"]:checked + label:after {
    @include fieldsetInputRadioChecked;
}

.field-element--small input[type="radio"]:checked + label:after {
    width: calc(var(--input-small-diameter) - 4px);
    height: calc(var(--input-small-diameter) - 4px);
}

/* White */
.field-element--white  {
    input[type="checkbox"] + label:before,
    input[type="radio"] + label:before {
        background-color: $color-white;
    }
}

/* input focus state */
.fieldset--multiradio:focus-within {
    outline: $color-grey-02 dashed 1px;
}
.field-element input[type="checkbox"]:focus + label {
    outline: $color-grey-06 auto 5px;
    outline-offset: -3px;
}

/* ---- Field errors ---- */
.field-element.field-element--error input[type="checkbox"] + label:before,
.field-element.field-element--error input[type="radio"] + label:before {
    background-color: lighten($color-utility-warning, 62%);
    border-color: lighten($color-utility-warning, 58%);
}

.field-element.field-element--warning input[type="checkbox"] + label:before,
.field-element.field-element--warning input[type="radio"] + label:before {
    background-color: lighten($color-utility-alert, 62%);
    border-color: lighten($color-utility-alert, 58%);
}

/* ---- Field errors ---- */
.field-element.field-element--error {
    label {
        color: $color-utility-warning;
    }

    input[type="checkbox"] + label:before,
    input[type="radio"] + label:before {
        background-color: lighten($color-utility-warning, 62%);
        border-color: lighten($color-utility-warning, 58%);
    }

    .pseudo-input {
        background-color: lighten($color-utility-warning, 62%);
        border-color: lighten($color-utility-warning, 58%);
    }
}

.field-element.field-element--warning {
    label {
        color: $color-utility-alert;
    }

    input[type="checkbox"] + label:before,
    input[type="radio"] + label:before {
        background-color: lighten($color-utility-alert, 62%);
        border-color: lighten($color-utility-alert, 58%);
    }

    .pseudo-input {
        background-color: lighten($color-utility-alert, 62%);
        border-color: lighten($color-utility-alert, 58%);
    }
}
