@charset "UTF-8";
/*
Variable Example
*/
/*
Breakpoint Mixins
*/
:root {
  --body-font-size: 1.6rem;
  --h1-font-size: 4rem;
  --h1-line-height: 1.2;
  --h2-font-size: 3.2rem;
  --h2-line-height: 1.5;
  --h3-font-size: 2.4rem;
  --h3-line-height: inherit;
  --h4-font-size: 2.2rem;
  --h4-line-height: 1.5;
  --h5-font-size: 2rem;
  --h5-line-height: inherit;
  --h6-font-size: 1.6rem;
  --h6-line-height: inherit;
  /* [02] */
  --giga-font-size: 4rem;
  --mega-font-size: 3.2rem;
  --kilo-font-size: 2.8rem;
  --milli-font-size: 1.2rem;
  --centi-font-size: 1.4rem;
}

@media screen and (min-width: 48em) {
  /* 768px */
  :root {
    --body-font-size: 1.6rem;
    --h1-font-size: 4rem;
    --h1-line-height: 1.2;
    --h2-font-size: 3.2rem;
    --h2-line-height: 1.5;
    --h3-font-size: 2.4rem;
    --h3-line-height: inherit;
    --h4-font-size: 2.2rem;
    --h4-line-height: 1.5;
    --h5-font-size: 2rem;
    --h5-line-height: inherit;
    --h6-font-size: 1.6rem;
    --h6-line-height: inherit;
    /* [02] */
    --giga-font-size: 7.2rem;
    --mega-font-size: 6.4rem;
    --kilo-font-size: 5.6rem;
    --milli-font-size: 1.2rem;
    --centi-font-size: 1.4rem;
  }
}
/*
Basic system font stacks
*/
/* Weights */
/* Size */
/* -----
* NOTES
* [02]
* A series of classes for setting massive type; for use in heroes, mastheads,
* promos, etc.
* As per: csswizardry.com/2012/02/pragmatic-practical-font-sizing-in-css
------ */
/* -----------------------------
File Path
-------------------------------- */
/* -----------------------------
Spacing
-------------------------------- */
/* -----------------------------
style variables
-------------------------------- */
/* -----------------------------
Header
-------------------------------- */
/* -----------------------------
Page
-------------------------------- */
/* ------------------------------------------------------
**** SCSS Inclusions
------------------------------------------------------ */
/* ---- Global Variables ---- */
/* ---- Mixins ---- */
:root {
  --button-border-width: 3px;
  --button-border-radius: 0;
}

/* --------------------------------
Text Link
----------------------------------- */
/* --------------------------------
Sizes
----------------------------------- */
/* Width - Inline Padding  */
/* Height - Block Padding */
/* Text Size */
/* -----------------------------
Visibility Utilities
------------------------------*/
/* -----------------------------
Clearfix
------------------------------*/
/* -----------------------------
SVG
------------------------------*/
/* -----------------------------
List Mixins
------------------------------*/
/* -----------------------------
Fallback logo image
------------------------------*/
:root {
  --field-default-border: 1px solid #d4d4d4;
  --field-default-background: #ffffff;
  --field-padding-vertical: 0.75em;
  --field-padding-horizontal: 0.88888em;
  --field-padding-vertical-sm: 0.4em;
  --field-padding-horizontal-sm: 0.8em;
  --field-padding-vertical-xsm: 0.3em;
  --field-padding-horizontal-xsm: 0.75em;
}

/* ---- Abstracts ---- */
/* Fade in */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Fade in down */
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}
/* Fade in up */
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
/* Fade in up margin */
@keyframes fadeInUpMargin {
  from {
    opacity: 0;
    margin-bottom: -20px;
  }
  to {
    opacity: 1;
    margin-bottom: 0;
  }
}
/* Grow in fade */
@keyframes growInFade {
  from {
    opacity: 0;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
/* ---- Highlight pulse ---- */
@keyframes highlightPulse {
  40% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
}
/* ---- Highlight pulse with outline ---- */
@keyframes highlightPulseOutline {
  0% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  13% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
  33% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    outline: 3px solid #FFC57D;
    outline-offset: 4px;
  }
  100% {
    outline: 3px solid transparent;
    outline-offset: 4px;
  }
}
.animate-fade {
  animation: fadeInDown 400ms ease-in-out;
}

.demo-animate .demo-animate__item {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.demo-animate:hover .demo-animate__item {
  clip: auto;
  height: auto;
  overflow: visible;
  position: relative;
  width: auto;
  padding: 5px;
  -webkit-animation: fadeInDown 400ms ease-in-out;
  animation: fadeInDown 400ms ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

/* ------------------------------------------------------
**** ATOMS
------------------------------------------------------ */
/* ---- Base HTML ---- */
@viewport {
  width: device-width;
  zoom: 1;
}
html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

* {
  margin: 0;
}

html,
body {
  background: #FFF;
  height: 100%;
  scroll-behavior: smooth;
}

html {
  font-size: 62.5%;
}

body {
  line-height: 1.5;
  font-size: var(--body-font-size);
  color: #2b2b2b;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#content {
  background-color: #ffffff;
}

.old-browser__banner {
  display: none;
  background: #cc1e34;
  position: fixed;
  padding: 10px 20px;
  color: #fff;
  top: 0;
  left: 0;
  right: 0;
  height: 40%;
  width: 100%;
  z-index: 9999;
}

.old-browser__banner a {
  color: #fff;
  text-decoration: underline;
  font-weight: bold;
}

.old-browser__banner a:hover,
.old-browser__banner a:focus,
.old-browser__banner a:active {
  color: #fff;
  text-decoration: none;
}

.old-browser__banner .old-browser__center {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  margin: auto;
}

.old-browser .old-browser__banner {
  display: table;
}

.row--vertical-gutters *[class*=col-xs],
.row--vertical-gutters *[class*=col-sm],
.row--vertical-gutters *[class*=col-md],
.row--vertical-gutters *[class*=col-lg] {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

/* ---- Unpadded row ---- */
.row-gap-0 {
  --gutter-width: 0rem;
  --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
  --half-gutter-width: calc((var(--gutter-width) * 0.5));
}

.row-gap-35 {
  --gutter-width: 35px;
  --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
  --half-gutter-width: calc((var(--gutter-width) * 0.5));
}

/* ---- Medium padded row ---- */
.row-gap-md {
  --gutter-width: 3.66666666vw;
  --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
  --half-gutter-width: calc((var(--gutter-width) * 0.5));
}
@media screen and (min-width: 48em) {
  .row-gap-md {
    --gutter-width: 3.83333334vw;
    --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
    --half-gutter-width: calc((var(--gutter-width) * 0.5));
  }
}
@media screen and (min-width: 100em) {
  .row-gap-md {
    --gutter-width: 60px;
    --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
    --half-gutter-width: calc((var(--gutter-width) * 0.5));
  }
}

/* ---- Large padded row ---- */
.row-gap-lg {
  --gutter-width: 4.8888888vw;
  --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
  --half-gutter-width: calc((var(--gutter-width) * 0.5));
}
@media screen and (min-width: 48em) {
  .row-gap-lg {
    --gutter-width: 5.11111vw;
    --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
    --half-gutter-width: calc((var(--gutter-width) * 0.5));
  }
}
@media screen and (min-width: 100em) {
  .row-gap-lg {
    --gutter-width: 80px;
    --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
    --half-gutter-width: calc((var(--gutter-width) * 0.5));
  }
}

.row-gap-col {
  --gutter-width: 7.3333333vw;
  --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
  --half-gutter-width: calc((var(--gutter-width) * 0.5));
}
@media screen and (min-width: 48em) {
  .row-gap-col {
    --gutter-width: 7.66666667vw;
    --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
    --half-gutter-width: calc((var(--gutter-width) * 0.5));
  }
}
@media screen and (min-width: 100em) {
  .row-gap-col {
    --gutter-width: 120px;
    --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
    --half-gutter-width: calc((var(--gutter-width) * 0.5));
  }
}

/* ---- Row - nowrap  ---- */
.row-nowrap {
  flex-wrap: nowrap;
}
@media screen and (min-width: 35em) {
  .row-nowrap--xsm {
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: 48em) {
  .row-nowrap--sm {
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: 62em) {
  .row-nowrap--md {
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: 75em) {
  .row-nowrap--lg {
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: 87.5em) {
  .row-nowrap--lg2 {
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: 100em) {
  .row-nowrap--xlg {
    flex-wrap: nowrap;
  }
}

img {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

:root {
  --default-container: 88%;
  --default-container-gutter: 6vw;
}

@media screen and (min-width: 48em) {
  :root {
    --default-container: 92%;
    --default-container-gutter: 4vw;
  }
}
@media screen and (min-width: 100em) {
  :root {
    --default-container: 1440px;
    --default-container-gutter: calc(50vw - (var(--default-container) / 2));
  }
}
@media screen and (min-width: 150em) {
  :root {
    --default-container-gutter: calc((2400px - var(--default-container)) / 2);
  }
}
.container {
  width: var(--default-container);
  margin: 0 auto;
}

/* [01] The bleed utility classes now live in the background colours file */
@media screen and (max-width: 61.9375em) {
  #wrap {
    overflow-x: hidden;
  }
}
@media screen and (min-width: 150em) {
  #wrap {
    max-width: 2400px;
    margin: 0 auto;
  }
}

/* [01]
    Bleeds commented so as to not bloat the css unnecessarily.
    Uncomment to use
*/
.bg-white {
  background-color: #ffffff;
}

.bg-lightgrey {
  background-color: #d4d4d4;
}
.bg-lightgrey.container--bleed-left {
  position: relative;
}
.bg-lightgrey.container--bleed-left:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100%;
  width: 100vw;
  background-color: #d4d4d4;
}
.bg-lightgrey.container--bleed-right {
  position: relative;
}
.bg-lightgrey.container--bleed-right:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  width: 100vw;
  background-color: #d4d4d4;
}

.bg-primary {
  background-color: #023578;
  /* [ 01 ]
  &.container--bleed-left {
      @include bleed-left($color-primary);
  }
  &.container--bleed-right {
      @include bleed-right($color-primary);
  }
  */
}

.bg-secondary {
  background-color: #2D96D3;
  /* [ 01 ]
  &.container--bleed-left {
      @include bleed-left($color-secondary);
  }
  &.container--bleed-right {
      @include bleed-right($color-secondary);
  }
  */
}

.bg-alternative {
  background-color: #001A44;
  /* [ 01 ]
  &.container--bleed-left {
      @include bleed-left($color-alternative);
  }
  &.container--bleed-right {
      @include bleed-right($color-alternative);
  }
  */
}

.bg-accent-01 {
  background-color: #EB8A01;
  /* [ 01 ]
  &.container--bleed-left {
      @include bleed-left(color-accent-01);
  }
  &.container--bleed-right {
      @include bleed-right(color-accent-01);
  }
  */
}

.bg-accent-02 {
  background-color: #dce3ec;
  /* [ 01 ]
  &.container--bleed-left {
      @include bleed-left(color-accent-02);
  }
  &.container--bleed-right {
      @include bleed-right(color-accent-02);
  }
  */
}

:root {
  --section-small: 15px;
  --section: 20px;
  --section-large: 40px;
}

@media screen and (min-width: 22.5em) {
  :root {
    --section-small: 24px;
    --section: 40px;
    --section-large: 80px;
  }
}
@media screen and (min-width: 48em) {
  :root {
    --section-small: 40px;
    --section: 80px;
    --section-large: 120px;
  }
}
.section,
.section-regular {
  padding-top: var(--section);
  padding-bottom: var(--section);
}

.section-small {
  padding-top: var(--section-small);
  padding-bottom: var(--section-small);
}

.section-large {
  padding-top: var(--section-large);
  padding-bottom: var(--section-large);
}

.section-t0 {
  padding-top: 0;
}

.section-b0 {
  padding-bottom: 0;
}

.vertical-gap--sep-line:before {
  content: "";
  display: block;
  border-bottom: 1px solid #d4d4d4;
}

.block {
  margin-top: 20px;
  margin-bottom: 20px;
}

.block-mb {
  margin-bottom: 20px;
}

.block-large {
  margin-top: 20px;
  margin-bottom: 20px;
}

.block-mb-large {
  margin-bottom: 20px;
}

@media screen and (min-width: 22.5em) {
  .block-large {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .block-mb-large {
    margin-bottom: 25px;
  }
}
/* BP small */
@media screen and (min-width: 48em) {
  .block-large {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .block-mb-large {
    margin-bottom: 30px;
  }
}
.box {
  padding: 16px;
}
.box > *:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 22.5em) {
  .box {
    padding: 24px;
  }
}
@media screen and (min-width: 48em) {
  .box {
    padding: 32px;
  }
}

.box--tiny {
  padding: 16px;
}
.box--tiny > *:last-child {
  margin-bottom: 0;
}

.box--small {
  padding: 16px;
}
.box--small > *:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 22.5em) {
  .box--small {
    padding: 24px;
  }
}

.box--large {
  padding: 16px;
}
.box--large > *:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 22.5em) {
  .box--large {
    padding: 24px;
  }
}
@media screen and (min-width: 48em) {
  .box--large {
    padding: 40px;
  }
}

.box-lined {
  border: 1px solid #F0F0F0;
  border-radius: 3px;
  margin-bottom: var(--body-font-size);
  border-left: 3px solid #023578;
}

.box-lined--grey {
  border: 1px solid #F0F0F0;
  border-radius: 3px;
  margin-bottom: var(--body-font-size);
  border-left: 3px solid #636363;
}

.box-lined--primary {
  border: 1px solid #F0F0F0;
  border-radius: 3px;
  margin-bottom: var(--body-font-size);
  border-left: 3px solid #023578;
}

.box-lined--secondary {
  border: 1px solid #F0F0F0;
  border-radius: 3px;
  margin-bottom: var(--body-font-size);
  border-left: 3px solid #2D96D3;
}

.box-lined-bottom {
  border: 1px solid #F0F0F0;
  border-radius: 3px;
  margin-bottom: var(--body-font-size);
  border-bottom: 3px solid #023578;
}
.box-lined-bottom.box-lined--grey {
  border: 1px solid #F0F0F0;
  border-radius: 3px;
  margin-bottom: var(--body-font-size);
  border-bottom: 3px solid #636363;
}
.box-lined-bottom.box-lined--primary {
  border: 1px solid #F0F0F0;
  border-radius: 3px;
  margin-bottom: var(--body-font-size);
  border-bottom: 3px solid #023578;
}
.box-lined-bottom.box-lined--secondary {
  border: 1px solid #F0F0F0;
  border-radius: 3px;
  margin-bottom: var(--body-font-size);
  border-bottom: 3px solid #2D96D3;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

@media screen and (min-width: 35em) {
  .text-align-right--xsm {
    text-align: right;
  }

  .text-align-left--xsm {
    text-align: left;
  }

  .text-align-center--xsm {
    text-align: center;
  }
}
@media screen and (min-width: 48em) {
  .text-align-right--sm {
    text-align: right;
  }

  .text-align-left--sm {
    text-align: left;
  }

  .text-align-center--sm {
    text-align: center;
  }
}
/* BP medium */
@media screen and (min-width: 62em) {
  .text-align-right--md {
    text-align: right;
  }

  .text-align-left--md {
    text-align: left;
  }

  .text-align-center--md {
    text-align: center;
  }
}
/* BP large */
@media screen and (min-width: 75em) {
  .text-align-right--lg {
    text-align: right;
  }

  .text-align-left--lg {
    text-align: left;
  }

  .text-align-center--lg {
    text-align: center;
  }
}
.float-left,
.float-center,
.float-right {
  margin: 30px auto;
  display: block;
  clear: both;
}

/* BP Smaller */
@media screen and (min-width: 35em) {
  .float-left {
    margin: 0 30px 30px 0;
    float: left;
  }

  .float-right {
    margin: 0 0 30px 30px;
    float: right;
  }
}
/* ---- Text ---- */
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 0 0 var(--paragraph-break);
  font-family: "Roboto Condensed", Arial Narrow, sans-serif;
  font-weight: bold;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-weight: normal;
  line-height: 1;
  color: #8a7b7b;
}

p + h1,
p + h2,
p + h3,
p + h4,
p + h5,
p + h6 {
  margin-top: 3rem;
}

h1, .h1 {
  font-size: var(--h1-font-size);
  line-height: var(--h1-line-height);
  font-family: "Roboto Condensed", Arial Narrow, sans-serif;
  color: inherit;
}

h2, .h2 {
  font-size: var(--h2-font-size);
  line-height: var(--h2-line-height);
  font-family: "Roboto Condensed", Arial Narrow, sans-serif;
  color: #023578;
}

h3, .h3 {
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  font-family: "Roboto Condensed", Arial Narrow, sans-serif;
  color: #2D96D3;
}

h4, .h4 {
  font-size: var(--h4-font-size);
  line-height: var(--h4-line-height);
  color: inherit;
}

h5, .h5 {
  font-size: var(--h5-font-size);
  line-height: var(--h5-line-height);
  color: inherit;
}

h6, .h6 {
  font-size: var(--h6-font-size);
  line-height: var(--h6-line-height);
  color: inherit;
}

p,
ol,
ul,
dl,
address {
  margin: 0 0 var(--body-font-size);
}

small {
  font-size: var(--milli-font-size);
}

.heading-inline {
  display: inline;
  float: left;
  padding: 0;
}

.heading-w-subtitle {
  margin-bottom: 0;
}

:root {
  --paragraph-break: 1.6rem;
}

p,
address {
  margin: 0 0 var(--paragraph-break);
}

/* ---- Text selection ---- */
::-moz-selection {
  color: #000;
  background: #FFC57D;
}

::selection {
  color: #000;
  background: #FFC57D;
}

/* ---- Text level elements ---- */
abbr[title] {
  border-bottom: 1px dotted #c6bfbf;
  cursor: help;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

ins {
  background-color: #F0F0F0;
  color: #2b2b2b;
  text-decoration: none;
}

mark {
  background-color: #F0F0F0;
  color: #2b2b2b;
  font-style: italic;
  font-weight: bold;
}

pre,
code,
kbd,
samp {
  font-family: Menlo, Consolas, Monaco, Liberation Mono, Lucida Console, monospace;
  color: #2b2b2b;
  background: #F0F0F0;
  background: rgba(0, 0, 0, 0.07);
  padding: 0 2px;
  margin: 0 -2px;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

small {
  font-size: 1.28rem;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 2rem 0;
  padding: 0;
}

ul,
ol dl {
  margin: 0 0 1.6rem;
  padding: 0 0 0 2rem;
}

li ul,
li ol {
  margin: 1rem 0;
}

ol {
  list-style-type: none;
  counter-reset: item;
}

ol ul {
  list-style-type: disc;
}

ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}

li ol > li {
  margin: 0;
}

li ol > li:before {
  content: counters(item, ".") " ";
}

.content-block ul {
  padding-left: 24px;
}
.content-block ul li {
  margin-bottom: 16px;
}
.content-block ul li::marker {
  content: url(../assets/deco-blue-grid.svg) "  ";
}

ul.inline,
ol.inline {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-flow: row wrap;
  gap: 4px 8px;
}

ul.inline > li,
ol.inline > li {
  display: inline-block;
  padding-left: 12px;
  padding-right: 12px;
}
@supports (display: flex) {
  ul.inline > li,
ol.inline > li {
    padding-left: 0;
    padding-right: 0;
  }
}

/* ---- Badge ---- */
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: var(--milli-font-size);
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  text-decoration: none;
  outline-offset: 2px;
  box-shadow: 0 0 0 transparent;
  background-color: #F0F0F0;
  color: #2b2b2b;
  transition: color 250ms ease-in-out, outline 250ms ease-in-out, background-color 250ms ease-in-out, box-shadow 250ms ease-in-out;
}
.badge[href]:hover, .badge[href]:focus {
  outline: thin dotted #ffffff;
  box-shadow: 0 2px 8px rgba(15, 15, 15, 0.33);
}
.badge[href]:focus {
  outline: thin solid #ffffff;
}

/* ---- Buttons and links ---- */
:root {
  --text-link-colour: #023578;
  --text-link-hover-colour: darken(#023578, 20%);
}

a {
  color: var(--text-link-colour);
  transition: color 250ms ease-in-out, opacity 250ms ease-in-out;
}

a:hover,
a:active,
a:focus {
  color: var(--text-link-hover-colour);
}

/* Address `outline` inconsistency between Chrome and other browsers. */
a:focus {
  outline: thin dotted;
}

/* Improve readability when focused and also mouse hovered in all browsers. */
a:active,
a:hover {
  outline: 0;
}

/* Include file type and size in document links */
a.document::after {
  content: " (" attr(data-ext) " " attr(data-size) ")";
}

:target {
  animation: highlightPulse 700ms ease;
  outline: 3px solid #FFC57D;
  outline-offset: 4px;
}

.active-target {
  animation: highlightPulseOutline 2100ms ease;
}

.content-block a[href$=".pdf"] {
  margin-right: 4px;
}
.content-block a[href$=".pdf"]::after {
  content: " ";
  display: inline-block;
  width: 1.25em;
  height: 1.25em;
  vertical-align: middle;
  margin-left: 0.25em;
  margin-top: -0.25em;
  background: url(../assets/icon-system/icon_pdf.svg) no-repeat center left;
  background-size: contain;
}
.content-block a.button[href$=".pdf"]::after {
  margin-left: 0.12em;
  margin-right: -0.25em;
  background-image: url(../assets/icon-system/icon_pdf--white.svg);
}
.content-block .li > a[href$=".pdf"] + a[href$=".pdf"]::after {
  content: none;
}

.button {
  text-align: center;
  vertical-align: middle;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  white-space: normal;
  cursor: pointer;
  border-radius: var(--button-border-radius);
  outline: none;
  opacity: 1;
  position: relative;
  margin: 0;
  text-decoration: none;
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Roboto Condensed", Arial Narrow, sans-serif;
  line-height: normal;
  color: #ffffff;
  background: #023578;
  border: var(--button-border-width) solid #023578;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
}
.button svg {
  height: 1em;
  width: 1em;
}
.button svg path,
.button svg circle,
.button svg ellipse,
.button svg rect,
.button svg line,
.button svg polygon {
  fill: #ffffff;
}
.button:hover, .button:focus, .button:active {
  background-color: #011f46;
  border-color: #011f46;
  color: #ffffff;
}
.button:hover svg path,
.button:hover svg circle,
.button:hover svg ellipse,
.button:hover svg rect,
.button:hover svg line,
.button:hover svg polygon, .button:focus svg path,
.button:focus svg circle,
.button:focus svg ellipse,
.button:focus svg rect,
.button:focus svg line,
.button:focus svg polygon, .button:active svg path,
.button:active svg circle,
.button:active svg ellipse,
.button:active svg rect,
.button:active svg line,
.button:active svg polygon {
  fill: #ffffff;
}

/* ---- Sizes ---- */
/* Small */
.button--small {
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-size: 80%;
}

@media screen and (min-width: 35em) {
  .button--large {
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 1em;
    padding-bottom: 1em;
    font-size: 150%;
  }
}

/* - Button icons - */
.button__icon {
  display: inline-block;
  vertical-align: top;
}
.button__icon--right {
  margin-left: 0.25em;
  margin-right: -0.25em;
}
.button__icon--left {
  margin-right: 0.25em;
  margin-left: -0.25em;
}
.button__icon svg {
  height: 1em;
  width: 1em;
}
.button__icon svg path,
.button__icon svg circle,
.button__icon svg ellipse,
.button__icon svg rect,
.button__icon svg line,
.button__icon svg polygon {
  fill: #ffffff;
  transition: fill 250ms ease-in-out;
}

/* ---- Button Colours ---- */
.button--alt {
  text-align: center;
  vertical-align: middle;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  white-space: normal;
  cursor: pointer;
  border-radius: var(--button-border-radius);
  outline: none;
  opacity: 1;
  position: relative;
  margin: 0;
  text-decoration: none;
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Roboto Condensed", Arial Narrow, sans-serif;
  line-height: normal;
  color: #ffffff;
  background: #EB8A01;
  border: var(--button-border-width) solid #EB8A01;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
}
.button--alt svg {
  height: 1em;
  width: 1em;
}
.button--alt svg path,
.button--alt svg circle,
.button--alt svg ellipse,
.button--alt svg rect,
.button--alt svg line,
.button--alt svg polygon {
  fill: #ffffff;
}
.button--alt:hover, .button--alt:focus, .button--alt:active {
  background-color: #b86c01;
  border-color: #b86c01;
  color: #ffffff;
}
.button--alt:hover svg path,
.button--alt:hover svg circle,
.button--alt:hover svg ellipse,
.button--alt:hover svg rect,
.button--alt:hover svg line,
.button--alt:hover svg polygon, .button--alt:focus svg path,
.button--alt:focus svg circle,
.button--alt:focus svg ellipse,
.button--alt:focus svg rect,
.button--alt:focus svg line,
.button--alt:focus svg polygon, .button--alt:active svg path,
.button--alt:active svg circle,
.button--alt:active svg ellipse,
.button--alt:active svg rect,
.button--alt:active svg line,
.button--alt:active svg polygon {
  fill: #ffffff;
}

.button--secondary {
  text-align: center;
  vertical-align: middle;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  white-space: normal;
  cursor: pointer;
  border-radius: var(--button-border-radius);
  outline: none;
  opacity: 1;
  position: relative;
  margin: 0;
  text-decoration: none;
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Roboto Condensed", Arial Narrow, sans-serif;
  line-height: normal;
  color: #ffffff;
  background: #2D96D3;
  border: var(--button-border-width) solid #2D96D3;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
}
.button--secondary svg {
  height: 1em;
  width: 1em;
}
.button--secondary svg path,
.button--secondary svg circle,
.button--secondary svg ellipse,
.button--secondary svg rect,
.button--secondary svg line,
.button--secondary svg polygon {
  fill: #ffffff;
}
.button--secondary:hover, .button--secondary:focus, .button--secondary:active {
  background-color: #2478a9;
  border-color: #2478a9;
  color: #ffffff;
}
.button--secondary:hover svg path,
.button--secondary:hover svg circle,
.button--secondary:hover svg ellipse,
.button--secondary:hover svg rect,
.button--secondary:hover svg line,
.button--secondary:hover svg polygon, .button--secondary:focus svg path,
.button--secondary:focus svg circle,
.button--secondary:focus svg ellipse,
.button--secondary:focus svg rect,
.button--secondary:focus svg line,
.button--secondary:focus svg polygon, .button--secondary:active svg path,
.button--secondary:active svg circle,
.button--secondary:active svg ellipse,
.button--secondary:active svg rect,
.button--secondary:active svg line,
.button--secondary:active svg polygon {
  fill: #ffffff;
}

.button--warning {
  text-align: center;
  vertical-align: middle;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  white-space: normal;
  cursor: pointer;
  border-radius: var(--button-border-radius);
  outline: none;
  opacity: 1;
  position: relative;
  margin: 0;
  text-decoration: none;
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Roboto Condensed", Arial Narrow, sans-serif;
  line-height: normal;
  color: #ffffff;
  background: #9E0E0B;
  border: var(--button-border-width) solid #9E0E0B;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
}
.button--warning svg {
  height: 1em;
  width: 1em;
}
.button--warning svg path,
.button--warning svg circle,
.button--warning svg ellipse,
.button--warning svg rect,
.button--warning svg line,
.button--warning svg polygon {
  fill: #ffffff;
}
.button--warning:hover, .button--warning:focus, .button--warning:active {
  background-color: #6e0a08;
  border-color: #6e0a08;
  color: #ffffff;
}
.button--warning:hover svg path,
.button--warning:hover svg circle,
.button--warning:hover svg ellipse,
.button--warning:hover svg rect,
.button--warning:hover svg line,
.button--warning:hover svg polygon, .button--warning:focus svg path,
.button--warning:focus svg circle,
.button--warning:focus svg ellipse,
.button--warning:focus svg rect,
.button--warning:focus svg line,
.button--warning:focus svg polygon, .button--warning:active svg path,
.button--warning:active svg circle,
.button--warning:active svg ellipse,
.button--warning:active svg rect,
.button--warning:active svg line,
.button--warning:active svg polygon {
  fill: #ffffff;
}

.button--neutral {
  text-align: center;
  vertical-align: middle;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  white-space: normal;
  cursor: pointer;
  border-radius: var(--button-border-radius);
  outline: none;
  opacity: 1;
  position: relative;
  margin: 0;
  text-decoration: none;
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Roboto Condensed", Arial Narrow, sans-serif;
  line-height: normal;
  color: #2b2b2b;
  background: #d4d4d4;
  border: var(--button-border-width) solid #d4d4d4;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
}
.button--neutral svg {
  height: 1em;
  width: 1em;
}
.button--neutral svg path,
.button--neutral svg circle,
.button--neutral svg ellipse,
.button--neutral svg rect,
.button--neutral svg line,
.button--neutral svg polygon {
  fill: #2b2b2b;
}
.button--neutral:hover, .button--neutral:focus, .button--neutral:active {
  background-color: #bbbbbb;
  border-color: #bbbbbb;
  color: #2b2b2b;
}
.button--neutral:hover svg path,
.button--neutral:hover svg circle,
.button--neutral:hover svg ellipse,
.button--neutral:hover svg rect,
.button--neutral:hover svg line,
.button--neutral:hover svg polygon, .button--neutral:focus svg path,
.button--neutral:focus svg circle,
.button--neutral:focus svg ellipse,
.button--neutral:focus svg rect,
.button--neutral:focus svg line,
.button--neutral:focus svg polygon, .button--neutral:active svg path,
.button--neutral:active svg circle,
.button--neutral:active svg ellipse,
.button--neutral:active svg rect,
.button--neutral:active svg line,
.button--neutral:active svg polygon {
  fill: #2b2b2b;
}

.button--positive {
  text-align: center;
  vertical-align: middle;
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  white-space: normal;
  cursor: pointer;
  border-radius: var(--button-border-radius);
  outline: none;
  opacity: 1;
  position: relative;
  margin: 0;
  text-decoration: none;
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Roboto Condensed", Arial Narrow, sans-serif;
  line-height: normal;
  color: #ffffff;
  background: #26883A;
  border: var(--button-border-width) solid #26883A;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out, color 250ms ease-in-out;
}
.button--positive svg {
  height: 1em;
  width: 1em;
}
.button--positive svg path,
.button--positive svg circle,
.button--positive svg ellipse,
.button--positive svg rect,
.button--positive svg line,
.button--positive svg polygon {
  fill: #ffffff;
}
.button--positive:hover, .button--positive:focus, .button--positive:active {
  background-color: #1b6029;
  border-color: #1b6029;
  color: #ffffff;
}
.button--positive:hover svg path,
.button--positive:hover svg circle,
.button--positive:hover svg ellipse,
.button--positive:hover svg rect,
.button--positive:hover svg line,
.button--positive:hover svg polygon, .button--positive:focus svg path,
.button--positive:focus svg circle,
.button--positive:focus svg ellipse,
.button--positive:focus svg rect,
.button--positive:focus svg line,
.button--positive:focus svg polygon, .button--positive:active svg path,
.button--positive:active svg circle,
.button--positive:active svg ellipse,
.button--positive:active svg rect,
.button--positive:active svg line,
.button--positive:active svg polygon {
  fill: #ffffff;
}

.button--simple-text {
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Roboto Condensed", Arial Narrow, sans-serif;
  line-height: normal;
  color: #023578;
  background: transparent;
  transition: transform 250ms ease-in-out, background-color 250ms ease-in-out, color 250ms ease-in-out;
}
.button--simple-text:after {
  content: " >";
}
.button--simple-text svg {
  height: 1em;
  width: 1em;
}
.button--simple-text svg path,
.button--simple-text svg circle,
.button--simple-text svg ellipse,
.button--simple-text svg rect,
.button--simple-text svg line,
.button--simple-text svg polygon {
  fill: #023578;
}
.button--simple-text:hover, .button--simple-text:focus, .button--simple-text:active {
  background-color: transparent;
  color: #023578;
}
.button--simple-text:hover svg path,
.button--simple-text:hover svg circle,
.button--simple-text:hover svg ellipse,
.button--simple-text:hover svg rect,
.button--simple-text:hover svg line,
.button--simple-text:hover svg polygon, .button--simple-text:focus svg path,
.button--simple-text:focus svg circle,
.button--simple-text:focus svg ellipse,
.button--simple-text:focus svg rect,
.button--simple-text:focus svg line,
.button--simple-text:focus svg polygon, .button--simple-text:active svg path,
.button--simple-text:active svg circle,
.button--simple-text:active svg ellipse,
.button--simple-text:active svg rect,
.button--simple-text:active svg line,
.button--simple-text:active svg polygon {
  fill: #023578;
}

/* ---- Button Variations ---- */
.button--block {
  display: block;
  width: 100%;
}

.button[disabled],
.button--disabled {
  border: none;
  box-shadow: none;
  cursor: default;
  opacity: 0.4;
}
.button[disabled]:hover, .button[disabled]:focus, .button[disabled]:active,
.button--disabled:hover,
.button--disabled:focus,
.button--disabled:active {
  background-color: inherit;
  box-shadow: none;
  color: inherit;
  opacity: 0.4;
}

/* Firefox: Get rid of the inner focus border */
.button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/* ---- Images and Icons ---- */
/*
svg path types
path,
circle,
ellipse,
rect,
line,
polygon
*/
.icon {
  display: inline-block;
  vertical-align: middle;
}

.icon svg {
  width: 16px;
  height: 16px;
}

.icon--24px svg {
  width: 24px;
  height: 24px;
}

.icon--32px svg {
  width: 32px;
  height: 32px;
}

.icon--40px svg {
  width: 40px;
  height: 40px;
}

.icon--48px svg {
  width: 48px;
  height: 48px;
}

.icon--52px svg {
  width: 52px;
  height: 52px;
}

.icon--60px svg {
  width: 60px;
  height: 60px;
}

.icon--68px svg {
  width: 68px;
  height: 68px;
}

/* Icon system colours */
.icon--primary path,
.icon--primary circle,
.icon--primary ellipse,
.icon--primary rect,
.icon--primary line,
.icon--primary polygon {
  fill: #023578;
}

.icon--secondary path,
.icon--secondary circle,
.icon--secondary ellipse,
.icon--secondary rect,
.icon--secondary line,
.icon--secondary polygon {
  fill: #2D96D3;
}

.icon--alternative path,
.icon--alternative circle,
.icon--alternative ellipse,
.icon--alternative rect,
.icon--alternative line,
.icon--alternative polygon {
  fill: #001A44;
}

.icon--current-color path,
.icon--current-color circle,
.icon--current-color ellipse,
.icon--current-color rect,
.icon--current-color line,
.icon--current-color polygon {
  fill: currentColor;
}

.icon--white path,
.icon--white circle,
.icon--white ellipse,
.icon--white rect,
.icon--white line,
.icon--white polygon {
  fill: #ffffff;
}

.icon--accent-01 path,
.icon--accent-01 circle,
.icon--accent-01 ellipse,
.icon--accent-01 rect,
.icon--accent-01 line,
.icon--accent-01 polygon {
  fill: #EB8A01;
}

.icon--accent-02 path,
.icon--accent-02 circle,
.icon--accent-02 ellipse,
.icon--accent-02 rect,
.icon--accent-02 line,
.icon--accent-02 polygon {
  fill: #dce3ec;
}

/* ---- Video ---- */
.widget-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  clear: both;
}
.richtext-video-wrapper .widget-video {
  margin-top: 24px;
  margin-bottom: 24px;
}

.widget-video iframe,
.widget-video object,
.widget-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

iframe {
  border: 0;
}

.widget-map {
  position: relative;
  padding-bottom: 35%;
  height: 0;
  overflow: hidden;
  clear: both;
  margin-top: 24px;
  margin-bottom: 24px;
}
.widget-map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

/* ---- Tables ---- */
table {
  border-collapse: collapse;
  border: 1px solid #7f7f7f;
  width: 100%;
}
.content-block table {
  margin-bottom: 24px;
}

th,
td {
  padding: 5px 10px;
  border: 1px solid #7f7f7f;
}

th,
thead td {
  background: #d4d4d4;
}

.table--content-standard {
  width: 100%;
  margin: 2rem 0;
}

.table--content-standard caption {
  text-align: left;
  padding-bottom: 13px;
}

.table--content-standard th,
.table--content-standard thead td {
  font-weight: bold;
  background-color: #d4d4d4;
}

.table--content-standard td {
  background-color: #ffffff;
}

.table--content-standard th,
.table--content-standard td {
  border: 1px solid #9c9c9c;
  padding: 12px 15px;
  text-align: left;
}

.table--content-small {
  font-size: 1.3rem;
}

.table--content-small th,
.table--content-small td {
  padding: 5px 10px;
}

.table--content-small th,
.table--content-small thead td {
  font-size: 1.4rem;
}

@media screen and (max-width: 35em) {
  .table--responsive {
    font-size: var(--centi-font-size);
  }

  .table--responsive,
.table--responsive tbody,
.table--responsive tr {
    display: block;
    border: none;
  }

  .table--responsive thead,
.table--responsive th {
    display: none;
  }

  .table--responsive tr {
    display: table;
    width: 100%;
  }

  .table--responsive tr.table--responsive__first-row {
    display: none;
  }

  .table--responsive td {
    display: table-row;
    padding: 0;
  }

  .table--responsive td[data-title]:before {
    content: attr(data-title);
    font-weight: bold;
    background-color: #F0F0F0;
    border-right: 1px solid #d7d7d7;
  }

  .table--responsive td[data-title] {
    border-left: 1px solid #d7d7d7;
    border-right: 1px solid #d7d7d7;
    border-top: 1px solid #d7d7d7;
  }

  .table--responsive tbody tr {
    margin-bottom: 20px;
    border-bottom: 1px solid #d7d7d7;
  }

  .table--responsive td[data-title]:before,
.table--responsive__content {
    padding: 5px 15px;
    display: table-cell;
  }
}
/* ---- Helpers ---- */
.clear {
  height: 0;
  clear: both;
  display: block;
}

.-clearfix:before, .-clearfix:after {
  content: " ";
  display: table;
}
.-clearfix:after {
  clear: both;
}

.-ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;
}

.-ir:before {
  content: "";
  display: block;
  width: 0;
  height: 150%;
}

/* ---- Hidden ---- */
.-hidden {
  display: none !important;
  visibility: hidden;
}

/* ---- Invisible ---- */
.-invisible {
  visibility: hidden;
}

/* ---- Visibility Hidden ---- */
.-vis-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.-vis-hidden.focusable:active,
.-vis-hidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/* Angle Brackets */
.-r-arrow-after:after {
  content: " >";
}

.-r-arrow-before:before {
  content: "> ";
}

.-l-arrow-after:after {
  content: " <";
}

.-l-arrow-before:before {
  content: "< ";
}

/* Ellipsis */
.ellipsis-2:after {
  content: "..";
}

.ellipsis-3:after {
  content: "...";
}

/* ------------------------------------------------------
**** MOLECULES
------------------------------------------------------ */
/* ---- Text ---- */
blockquote {
  margin: var(--body-font-size) 0;
  padding: 20px 25px 20px;
  background: #F0F0F0;
  font-style: italic;
  position: relative;
  clear: both;
}

blockquote *:first-child {
  margin-top: 0;
}

blockquote *:last-child {
  margin-bottom: 0;
}

blockquote:before,
blockquote:after {
  position: absolute;
  font-size: 13rem;
  display: block;
  height: 25px;
  width: 50px;
  color: #F0F0F0;
  font-style: italic;
  line-height: 10px;
  font-family: "Big Caslon", "Book Antiqua", "Palatino Linotype", Georgia, serif;
}

blockquote:before {
  content: "“";
  top: 38px;
  left: -8px;
}

blockquote:after {
  content: "”";
  bottom: -36px;
  right: 18px;
}

@media screen and (min-width: 62em) {
  .blockquote--left {
    float: left;
    width: 45%;
    margin-left: 0px;
    margin-right: 20px;
    clear: right;
  }

  .blockquote--right {
    float: right;
    width: 45%;
    margin-left: 20px;
    margin-right: 0px;
    clear: left;
  }
}
.highlight,
.highlight--right,
.highlight--left {
  margin: 0 0 var(--body-font-size);
  padding: 30px;
  background: #F0F0F0;
  clear: both;
}

/* BP medium */
@media screen and (min-width: 62em) {
  .highlight--left {
    float: left;
    width: 45%;
    margin-right: 20px;
    clear: right;
  }

  .highlight--right {
    float: right;
    width: 45%;
    margin-left: 20px;
    clear: left;
  }
}
.highlight *:last-child,
.highlight--right *:last-child,
.highlight--left *:last-child {
  margin-bottom: 0;
}

.expando {
  padding: 25px 45px;
  background-color: #F0F0F0;
  margin: 20px 0 30px;
}

/* - Expanded - */
.js .expando[aria-expanded=false] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.js .expando[aria-expanded=true] {
  clip: auto;
  height: auto;
  margin: 20px 0 30px;
  overflow: visible;
  position: relative;
  width: auto;
  padding: 25px 45px;
  animation: fadeInDown 200ms ease;
  animation-fill-mode: forwards;
}
.js .expando[aria-expanded=true] .expando-open__icon:before {
  transform: rotate(-45deg);
}
.js .expando[aria-expanded=true] .expando-open__icon:after {
  transform: rotate(45deg);
}

.js .expando p:last-of-type {
  margin-bottom: 0;
}

/* - Open button - */
.expando-trigger {
  -webkit-appearance: none;
  border: none;
  outline: none;
  background: none;
  padding: 6px 0 3px 40px;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  color: #EB8A01;
  position: relative;
  cursor: pointer;
  display: block;
  margin: 0 0 12px;
  font-size: inherit;
  line-height: inherit;
  transition: color 250ms ease-in-out;
  /* Hover */
}
.expando-trigger:hover, .expando-trigger:focus {
  color: #b86c01;
  text-decoration: underline;
}
.expando-trigger:hover .expando-open__icon, .expando-trigger:focus .expando-open__icon {
  background-color: #d4d4d4;
}
.expando-trigger:hover .expando-open__icon:before,
.expando-trigger:hover .expando-open__icon:after, .expando-trigger:focus .expando-open__icon:before,
.expando-trigger:focus .expando-open__icon:after {
  background-color: #0f0f0f;
}
.expando-trigger[aria-pressed=true] {
  outline: 1px dashed #d4d4d4;
}
.expando-trigger[aria-pressed=true] .expando-open__icon:before {
  transform: rotate(-45deg);
}
.expando-trigger[aria-pressed=true] .expando-open__icon:after {
  transform: rotate(45deg);
}

.expando-open__icon {
  position: absolute;
  top: 50%;
  left: 0;
  background-color: #F0F0F0;
  width: 26px;
  height: 26px;
  transform: translate(0, -50%);
}
.expando-open__icon:before, .expando-open__icon:after {
  content: " ";
  width: 14px;
  height: 2px;
  background-color: #636363;
  display: block;
  position: absolute;
  top: 12px;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: transform 250ms ease-in-out, background-color 250ms ease-in-out;
}
.expando-open__icon:after {
  transform: rotate(90deg);
}

/* - Close button - */
.expando__close {
  -webkit-appearance: none;
  border: none;
  outline: none;
  background: none;
  text-indent: -9999px;
  padding: 0;
  height: 26px;
  width: 26px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  /* Hover */
}
.expando__close:before, .expando__close:after {
  content: " ";
  width: 14px;
  height: 2px;
  background-color: #636363;
  display: block;
  position: absolute;
  top: 12px;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: transform 200ms ease;
}
.expando__close:before {
  transform: rotate(-45deg);
}
.expando__close:after {
  transform: rotate(45deg);
}
.expando__close:hover:before, .expando__close:hover:after {
  background-color: #0f0f0f;
}

.site-messages {
  list-style: none;
  margin: 0;
  padding: 0;
}
.site-messages__item {
  display: block;
  margin: 0 0 15px;
  padding: 10px 20px;
  vertical-align: bottom;
  text-align: left;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.2em;
}
.site-messages__item:last-child {
  margin-bottom: 0;
}
.site-messages__item.site-messages__item--mb {
  margin-bottom: var(--body-font-size);
}

.site-messages .error {
  background: #C32C2A;
  border-left: 4px solid #9E0E0B;
  color: #FFF !important;
}

.site-messages .confirm {
  background: #26883A;
  border-left: 4px solid #0E6F22;
  color: #FFF !important;
}

.site-messages .warning {
  background: #CD4C1D;
  border-left: 4px solid #A72D00;
  color: #FFF !important;
}

.site-messages a {
  color: #ffffff;
}

/* ---- Tooltips ---- */
.tooltip {
  position: relative;
}

.tooltip__title {
  display: inline-block;
  position: relative;
  z-index: 1;
  opacity: 1;
  font: inherit;
  transition: opacity 100ms ease-in-out;
}
.tooltip__title a {
  color: inherit;
}
.tooltip__title a:hover, .tooltip__title a:focus {
  color: #2b2b2b;
  text-decoration: none;
}

.tooltip__content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  border: 1px solid #d4d4d4;
  max-width: 270px;
  min-width: 120px;
  padding: 0;
  border-radius: 4px;
  margin-top: 7px;
  text-align: left;
  pointer-events: none;
  color: #636363;
  z-index: 100;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 400;
  white-space: normal;
  text-transform: none;
}
@media screen and (min-width: 48em) {
  .tooltip__content {
    min-width: 160px;
  }
}
@media screen and (min-width: 75em) {
  .tooltip__content {
    max-width: 400px;
    min-width: 280px;
  }
}
.tooltip__content p {
  margin-bottom: 0.25em;
}
.tooltip__content > *:last-child {
  margin-bottom: 0;
}
.tooltip__content small {
  font-size: 1.1rem;
}
.tooltip__content:before, .tooltip__content:after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 14px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #F0F0F0 transparent;
  z-index: 1;
}
.tooltip__content:after {
  left: 13px;
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #d4d4d4 transparent;
  z-index: 0;
}

/* Center align */
.tooltip--center .tooltip__content {
  left: 50%;
  margin-right: -50%;
  transform: translateX(-50%);
}

.tooltip--center .tooltip__content:before,
.tooltip--center .tooltip__content:after {
  left: 0;
  right: 0;
  margin: 0 auto;
}

/* Right align */
.tooltip--right .tooltip__content {
  right: 0;
  left: auto;
}

.tooltip--right .tooltip__content:before {
  right: 14px;
  left: auto;
}

/* Up */
.tooltip--up .tooltip__content {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 7px;
}

.tooltip--up .tooltip__content:before {
  border-width: 5px 5px 0 5px;
  border-color: #ffffff transparent transparent transparent;
  top: 100%;
}

/* Tooltip animations for hover state */
/* Fade in */
.tooltip:hover .tooltip__content,
.tooltip__title:hover + .tooltip__content {
  display: block;
  animation: fadeIn 200ms ease;
  animation-fill-mode: forwards;
}

/* Transition up */
.tooltip--transition-up .tooltip__content {
  -webkit-animation-name: fadeInUpMargin;
  animation-name: fadeInUpMargin;
}

/* Info icon */
.tooltip__icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: #9c9c9c;
  color: #636363;
  width: 1em;
  height: 1em;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  border: none;
  outline: none;
  padding: 0;
  margin: 0 0.2em;
}

.tooltip--icon .tooltip__icon {
  display: inline-block;
}

.tooltip__icon svg {
  display: block;
  width: 0.66em;
  height: 0.66em;
}
.tooltip__icon svg path {
  fill: #ffffff;
}

.tooltip--icon .tooltip__title {
  border-bottom: none;
  margin-right: 7px;
}

.tooltip--icon .tooltip__content {
  left: -8px;
  right: auto;
}

.tooltip.tooltip--icon.tooltip--center .tooltip__content {
  right: auto;
  left: 50%;
}

.tooltip--icon.tooltip--right .tooltip__content {
  right: -8px;
  left: auto;
}

/* Disabled */
.tooltip--disabled .tooltip__content {
  display: none;
}

.tooltip__header {
  background-color: #F0F0F0;
  padding: 10px;
  border-bottom: 1px solid #d4d4d4;
}
.tooltip__header .tooltip__header__title {
  font-family: "Roboto Condensed", Arial Narrow, sans-serif;
  font-weight: 700;
  color: #0f0f0f;
}

.tooltip__body {
  padding: 10px;
}

.tooltip__footer {
  padding: 10px;
}

.tooltip__body + .tooltip__footer {
  border-top: 1px solid #d4d4d4;
}

.richtext figure {
  display: inline-block;
  background: none;
  margin: 0 0 var(--body-font-size);
  padding: 0;
}
.richtext figure.image-full {
  display: block;
  text-align: center;
  float: none;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  background: none;
  padding: 0;
}
@media screen and (min-width: 48em) {
  .richtext figure.image-right {
    margin-left: calc(var(--body-font-size)*2);
    float: right;
  }
}
@media screen and (min-width: 48em) {
  .richtext figure.image-left {
    margin-right: calc(var(--body-font-size)*2);
    float: left;
  }
}
.richtext figure.image-center {
  display: block;
  text-align: center;
}
.richtext figure.image-center img {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
.richtext figure.widget-video {
  display: block;
  padding-bottom: 56.25%;
  margin: 0 0 1em;
}

figure.content-image {
  display: inline-block;
  background: none;
  margin-top: var(--body-font-size);
  margin-bottom: var(--body-font-size);
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}
figure.content-image.image-full {
  display: block;
  text-align: center;
  float: none;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  background: none;
  padding: 0;
}
@media screen and (min-width: 48em) {
  figure.content-image.image-right {
    margin-left: calc(var(--body-font-size)*2);
    float: right;
  }
}
@media screen and (min-width: 48em) {
  figure.content-image.image-left {
    margin-right: calc(var(--body-font-size)*2);
    float: left;
  }
}
figure.content-image.image-center {
  display: block;
  text-align: center;
}
figure.content-image.image-center img {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

figcaption {
  color: #7f7f7f;
  font-size: var(--milli-font-size);
  margin-top: 0.2em;
  display: block;
}

/* ---- Lists ---- */
.sidebar-linklist {
  margin: 0;
  padding: 0;
}

.sidebar-linklist__item {
  border-bottom: 1px solid #d6d6d6;
  position: relative;
}

.sidebar-linklist__item__button {
  display: block;
  background: none;
  border: none;
  padding: 10px 18px 10px 1.5em;
}

.sidebar-linklist__item__button:before {
  content: ">";
  position: absolute;
  left: 0;
  color: #000;
  top: calc(10px + 0.1em);
  transition: left 200ms ease-in-out, transform 200ms ease-in-out, color 200ms ease-in-out;
  transform: rotate(0deg);
  transform-origin: center;
}

.sidebar-linklist__item--expanded .sidebar-linklist__item__button:before,
.sidebar-linklist__item:hover .sidebar-linklist__item__button:before {
  left: 0.5em;
}

.sidebar-linklist__item--expanded .sidebar-linklist__item__button:before {
  transform: rotate(90deg);
}

.sidebar-linklist__item__sublist {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar-linklist__item__sublist__item {
  list-style: none;
}

.sidebar-linklist__item__sublist__item__link:before {
  content: "– ";
  color: #000;
}

.sidebar-linklist__item__sublist__item__link {
  display: block;
  text-decoration: none;
  padding: 0.25em 0.25em 0.25em 1.5em;
  transition: background-color 200ms ease-in-out;
}

.sidebar-linklist__item__sublist__item__link:hover,
.sidebar-linklist__item__sublist__item__link:focus,
.sidebar-linklist__item__sublist__item__link:active {
  background: rgba(0, 0, 0, 0.05);
}

.social-list {
  list-style: none;
  padding: 0;
  margin: 0px;
  margin-left: 4px;
  margin-right: 4px;
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  justify-content: flex-start;
}
.social-list--right {
  justify-content: flex-end;
}
.social-list--center {
  justify-content: center;
}
.social-list__item {
  display: inline-block;
}
.social-list__item a {
  color: inherit;
  display: block;
}
.social-list svg * {
  fill: currentColor;
}
.social-list--primary svg * {
  fill: rgba(2, 53, 120, 0.7);
}
.social-list--circle a {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 8px;
  background-color: currentColor;
  transition: background-color 250ms ease-in-out;
}
.social-list--circle svg * {
  fill: #023578;
}
.social-list--circle.social-list--primary a {
  background-color: #023578;
}
.social-list--circle.social-list--primary a:hover, .social-list--circle.social-list--primary a:focus, .social-list--circle.social-list--primary a:active {
  background-color: #2D96D3;
}
.social-list--circle.social-list--primary svg * {
  fill: #ffffff;
}

.share {
  display: flex;
  flex-wrap: wrap;
  background-color: #F0F0F0;
  padding: 20px;
  margin: 80px 0;
}
.share__title {
  flex: 1 1 auto;
}
.share__list {
  flex: 0 0 auto;
  list-style-type: none;
  padding-left: 0;
  display: flex;
  margin-bottom: 0;
}
.share__list__item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.5em;
}
.share__list__item a {
  color: #7f7f7f;
  text-decoration: none;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
}
.share__list__item a:hover, .share__list__item a:focus, .share__list__item a:active {
  color: #2b2b2b;
}
.share__list__item a:hover svg path,
.share__list__item a:hover svg circle,
.share__list__item a:hover svg ellipse,
.share__list__item a:hover svg rect,
.share__list__item a:hover svg line,
.share__list__item a:hover svg polygon, .share__list__item a:focus svg path,
.share__list__item a:focus svg circle,
.share__list__item a:focus svg ellipse,
.share__list__item a:focus svg rect,
.share__list__item a:focus svg line,
.share__list__item a:focus svg polygon, .share__list__item a:active svg path,
.share__list__item a:active svg circle,
.share__list__item a:active svg ellipse,
.share__list__item a:active svg rect,
.share__list__item a:active svg line,
.share__list__item a:active svg polygon {
  fill: #2b2b2b;
}
.share__list__item a svg {
  vertical-align: middle;
  margin-left: 4px;
}
.share__list__item a svg path,
.share__list__item a svg circle,
.share__list__item a svg ellipse,
.share__list__item a svg rect,
.share__list__item a svg line,
.share__list__item a svg polygon {
  transition: fill 250ms ease-in-out;
  fill: #9c9c9c;
}

.children-gallery-list {
  list-style-type: none;
  padding-left: 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}
.children-gallery-list--4 {
  grid-template-columns: repeat(4, 1fr);
}
@media screen and (min-width: 48em) {
  .children-gallery-list--2, .children-gallery-list--3, .children-gallery-list--4, .children-gallery-list--5 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 62em) {
  .children-gallery-list {
    gap: 24px;
  }
  .children-gallery-list--3 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 75em) {
  .children-gallery-list--3, .children-gallery-list--5 {
    gap: 40px;
    grid-template-columns: repeat(3, 1fr);
  }
  .children-gallery-list--4 {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (min-width: 87.5em) {
  .children-gallery-list {
    gap: 40px;
  }
  .children-gallery-list--5 {
    grid-template-columns: repeat(5, 1fr);
  }
}
.children-gallery-list__item__link {
  display: block;
  color: inherit;
  text-decoration-color: transparent;
  transition: text-decoration-color 250ms ease-in-out, color 250ms ease-in-out;
}
.children-gallery-list__item__link:hover, .children-gallery-list__item__link:focus, .children-gallery-list__item__link:active {
  color: #023578;
  text-decoration-color: #023578;
}
.children-gallery-list__item__image {
  display: block;
  background: #F0F0F0;
  background-image: url(../assets/logo.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
}
@supports (aspect-ratio: 3/2) {
  .children-gallery-list__item__image {
    aspect-ratio: 1/1;
  }
}
.children-gallery-list__item__title {
  padding-top: 8px;
}

.widget-FileList {
  background: #d4d4d4;
  padding: 20px;
  margin-bottom: 16px;
}

.widget-FileList ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.widget-FileList li {
  margin-bottom: 8px;
}

.widget-FileList li:last-child {
  margin-bottom: 0;
}

.widget-FileList .document {
  min-height: 24px;
  padding-left: 32px;
  background: url(../../assets/icon-system/icon_document.svg) no-repeat;
}

.widget-FileList .document-pdf {
  background-image: url(../../assets/icon-system/icon_pdf.svg);
}

.widget-FileList .document-png,
.widget-FileList .document-gif,
.widget-FileList .document-jpg {
  background-image: url(../../assets/icon-system/icon_image.svg);
}

.widget-FileList .document-rtf,
.widget-FileList .document-txt,
.widget-FileList .document-doc,
.widget-FileList .document-docx {
  background-image: url(../../assets/icon-system/icon_word-doc.svg);
}

.widget-FileList .document-csv,
.widget-FileList .document-xls,
.widget-FileList .document-xlsx {
  background-image: url(../../assets/icon-system/icon_spreadsheet.svg);
}

.widget-FileList .document-mp3,
.widget-FileList .document-aac,
.widget-FileList .document-oga {
  background-image: url(../../assets/icon-system/icon_sound.svg);
}

.widget-FileList .document-mp4,
.widget-FileList .document-mpeg,
.widget-FileList .document-mpg,
.widget-FileList .document-webm,
.widget-FileList .document-ogv,
.widget-FileList .document-aac,
.widget-FileList .document-avi,
.widget-FileList .document-mov,
.widget-FileList .document-wmv {
  background-image: url(../../assets/icon-system/icon_video.svg);
}

.widget-FileList a {
  display: inline-block;
  text-decoration: none;
  color: #023578;
  padding-bottom: 1px;
  border-bottom: 1px solid rgba(2, 53, 120, 0);
  transition: border-color 200ms ease-in-out;
}

.widget-FileList a:hover,
.widget-FileList a:focus,
.widget-FileList a:active {
  text-decoration: none;
  border-color: #023578;
}

@media screen and (min-width: 22.5em) {
  .widget-FileList {
    padding: 30px;
  }
}
.folder-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  clip: auto;
  height: auto;
  overflow: visible;
  position: relative;
  width: auto;
  animation: fadeInDown 200ms ease;
  animation-fill-mode: forwards;
}
.folder-list--closed {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.folder-list__spacer {
  min-height: 16px;
}
.folder-list__item {
  line-height: 1.25;
}
.folder-list__item__button {
  all: unset;
  cursor: pointer;
  border: 1px solid #d4d4d4;
  border-right: 1px solid rgba(212, 212, 212, 0);
  border-left: 1px solid rgba(212, 212, 212, 0);
  margin-top: -1px;
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
  gap: 6px;
  padding: 8px 0;
  transition: all 250ms ease-in-out;
}
.folder-list__item__button .icon {
  width: 16px;
  height: 16px;
  display: inline-grid;
  position: relative;
  transition: all 250ms ease-in-out;
  left: 0;
}
.folder-list__item__button:hover, .folder-list__item__button:focus, .folder-list__item__button:active {
  border-left: 1px solid #d4d4d4;
  border-right: 1px solid #d4d4d4;
  background-color: #F0F0F0;
}
.folder-list__item__button:hover .icon, .folder-list__item__button:focus .icon, .folder-list__item__button:active .icon {
  left: 4px;
}
.folder-list__item__button--open .icon {
  transform: rotate(90deg);
}
.folder-list__item__link {
  padding: 8px 4px;
  text-decoration: none;
  display: block;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: top;
  gap: 6px;
}
.folder-list__item__link .icon {
  margin-top: 2px;
}
.folder-list__item__link svg path {
  fill: currentColor;
}
.folder-list__item__link:hover, .folder-list__item__link:focus, .folder-list__item__link:active {
  color: #2D96D3;
}
.folder-list__item__empty-state {
  padding: 8px 4px;
  font-style: italic;
  color: #9c9c9c;
  display: block;
}
.folder-list .folder-list {
  margin-left: 8px;
  margin-bottom: 8px;
}
@media screen and (min-width: 35em) {
  .folder-list .folder-list {
    margin-left: 20px;
  }
}

.block-text-list {
  list-style-type: none;
  padding-left: 0;
}
.block-text-list__item {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.block-text-list__item:first-child {
  border-top: none;
}
.block-text-list__item__link {
  display: block;
  text-decoration: none;
  padding: 8px 0;
}
.block-text-list small {
  color: #2D96D3;
}

/* ---- Navigation ---- */
.breadcrumb {
  list-style: none;
  margin: 1.5rem 0;
  padding: 0;
  font-size: 1.4rem;
  color: #848484;
}

.breadcrumb > li {
  display: inline-block;
}

.breadcrumb > li:before {
  content: " / ";
}

.breadcrumb > li:first-child:before {
  content: none;
}

@media screen and (max-width: 47.9375em) {
  .breadcrumb {
    margin-top: 20px;
    margin-bottom: 10px;
    line-height: 1.4;
  }

  .breadcrumb > li {
    display: none;
    margin-left: 0;
  }

  .breadcrumb > li:nth-last-child(2) {
    display: inline-block;
  }

  .breadcrumb > li:nth-last-child(2):before {
    background: none;
    content: "< ";
    position: unset;
    font-weight: bold;
    position: relative;
    left: -1px;
    margin-left: 0px;
    top: 0;
  }
}
@media screen and (max-width: 35em) {
  /* code for the blog posts because the breadcrumbs are nonsensical */
  .blog-post-controller .breadcrumb > li {
    display: none;
    margin-left: 0;
  }
  .blog-post-controller .breadcrumb > li:nth-last-child(5) {
    display: inline-block;
  }
  .blog-post-controller .breadcrumb > li:nth-last-child(2) {
    display: none;
  }
  .blog-post-controller .breadcrumb > li:nth-last-child(5):before {
    background: none;
    content: "< ";
    position: unset;
    font-weight: bold;
    position: relative;
    left: -1px;
    margin-left: 0px;
    top: 0;
  }
}
.search-paginate {
  text-align: center;
  padding: var(--body-font-size);
  background: #b8b8b8;
}

.search-paginate .page {
  display: inline-block;
  background: #EB8A01;
  color: #fff;
  padding: 0.5rem 1.25rem;
  text-decoration: none;
  text-align: center;
  margin: 0 0.25rem;
}

.search-paginate .page.on {
  background: #854e01;
}

.search-paginate .page-next {
  margin-left: var(--body-font-size);
}

.search-paginate .page-prev {
  margin-right: var(--body-font-size);
}

@supports (display: flex) {
  .search-paginate {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
/*
* frankenMenu v1.0 // Karmabunny Web Design // built by Luke Underwood
*/
/** Desktop and Mobile styling
===================================*/
#frankenmenu-list,
#frankenmenu-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
}

#frankenmenu-list a {
  text-decoration: none;
  display: block;
}

/** Desktop menu only
===================================*/
@media screen and (min-width: 62em) {
  #frankenmenu {
    z-index: 100;
    width: 100%;
  }

  #mobile-header {
    display: none;
  }

  /* ---- All level menu items ---- */
  #frankenmenu-list {
    display: flex;
    /* ---- First level menu ---- */
    /* ---- Submenus ---- */
    /* ---- First level submenus ---- */
    /* ---- Right aligned submenus ---- */
    /* ---- Megamenu ---- */
    /* Remove relative positioning for mega menu */
    /* Megamenu preview */
    /* Megamenu columns */
    /* Megamenu submenus */
  }
  #frankenmenu-list .menu-item {
    position: relative;
  }
  #frankenmenu-list .menu-item--desktop-hidden {
    display: none;
  }
  #frankenmenu-list .menu-item-depth1 {
    text-align: center;
    flex: 1 1 auto;
    border-right: 1px solid rgba(255, 255, 255, 0.45);
    /* Hover */
    /* Current item */
    /* Ancestor item */
  }
  #frankenmenu-list .menu-item-depth1:first-child {
    border-left: 1px solid rgba(255, 255, 255, 0.45);
  }
  #frankenmenu-list .menu-item-depth1 > a {
    display: block;
    color: #ffffff;
    font-size: 110%;
    padding: 0.75em 8px;
    font-weight: 700;
    transition: background 250ms ease-in-out;
  }
  #frankenmenu-list .menu-item-depth1 > a:hover,
#frankenmenu-list .menu-item-depth1 > a:focus, #frankenmenu-list .menu-item-depth1.frankenhover > a, #frankenmenu-list .menu-item-depth1.frankenhold > a {
    color: #ffffff;
    background-color: #2D96D3;
  }
  #frankenmenu-list .menu-item-depth1.menu-current-item > a, #frankenmenu-list .menu-item-depth1.menu-current-item > a {
    background-color: #001A44;
    color: #ffffff;
  }
  #frankenmenu-list .menu-item-depth1 > .sub-menu {
    background-color: #2a8dc7;
    color: #ffffff;
  }
  #frankenmenu-list .sub-menu {
    display: none;
    min-width: 200px;
    position: absolute;
    box-shadow: 0 0 16px 1px rgba(0, 0, 0, 0.4);
    z-index: 1;
    min-width: 250px;
    max-width: 350px;
    text-align: left;
    top: 0;
    left: 100%;
  }
  #frankenmenu-list .sub-menu a {
    border-top: 1px solid #F0F0F0;
    padding: 9px 18px 10px;
    color: #023578;
    font-size: 1.5rem;
    background-color: #ffffff;
    transition: background-color 200ms ease, color 200ms ease;
  }
  #frankenmenu-list .sub-menu .menu-item:first-child a {
    border-top: none;
  }
  #frankenmenu-list .sub-menu .menu-item > a:hover,
#frankenmenu-list .sub-menu .menu-item > a:focus,
#frankenmenu-list .sub-menu .menu-item.frankenhover > a,
#frankenmenu-list .sub-menu .menu-item.frankenhold > a {
    background: #2D96D3;
    color: #ffffff;
  }
  .frankenhold > #frankenmenu-list .sub-menu {
    display: block !important;
    opacity: 1 !important;
  }
  #frankenmenu-list .sub-menu-depth1 {
    top: 100%;
    left: auto;
  }
  #frankenmenu-list .sub-menu.submenu-right-align,
#frankenmenu-list .sub-menu.submenu-right-align .sub-menu {
    left: auto;
    right: 100%;
  }
  #frankenmenu-list .sub-menu-depth1.submenu-right-align {
    left: auto;
    right: 0;
  }
  #frankenmenu-list .menu-item-subnav {
    display: none;
  }
  #frankenmenu-list .mega-menu {
    left: 0;
    right: 0;
    background-color: #ffffff;
    text-align: left;
    top: 100%;
    position: absolute;
    border-bottom: 4px solid #d4d4d4;
    z-index: 51;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
  }
  .frankenmenu-mega #frankenmenu-list .menu-item {
    position: static;
  }
  #frankenmenu-list .menu-item.frankenhover .mega-menu,
#frankenmenu-list .menu-item.frankenhold .mega-menu {
    padding: 40px 0;
    width: auto;
    height: auto;
    clip: auto;
    overflow: visible;
    margin: auto;
  }
  #frankenmenu-list .menu-item.frankenhold .mega-menu {
    display: block !important;
    height: auto !important;
  }
  #frankenmenu-list .mega-menu-preview {
    float: left;
    color: #ffffff;
    padding: 18px 20px;
    background-color: #636363;
    width: 22%;
    margin-right: 3%;
  }
  #frankenmenu-list .mega-menu-preview-image {
    height: 120px;
    background: url(../images/mega-menu-image-fallback.jpg) no-repeat center center;
    background-size: cover;
  }
  #frankenmenu-list .mega-menu-preview-text {
    margin-bottom: 0;
    line-height: 1.2;
  }
  #frankenmenu-list .mega-menu .mega-menu-columns {
    float: left;
    width: 75%;
  }
  #frankenmenu-list .mega-menu-column {
    width: 31.33333333%;
    float: left;
    margin-right: 3%;
  }
  #frankenmenu-list .mega-menu-column:last-child {
    margin-right: 0;
  }
  #frankenmenu-list .mega-menu-submenu .menu-item {
    border-top: 1px solid #d4d4d4;
  }
  #frankenmenu-list .mega-menu-submenu .menu-item:last-child {
    border-bottom: 1px solid #d4d4d4;
  }
  #frankenmenu-list .mega-menu-submenu a {
    color: #000;
    padding: 5px 0;
  }

  /* ---- Custom Sub-navigation ---- */
  #frankenmenu-subnav {
    float: right;
  }

  #frankenmenu-subnav-list,
#frankenmenu-subnav-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  #frankenmenu-subnav-list .menu-item-depth1 {
    display: inline-block;
  }
  #frankenmenu-subnav-list .menu-item-depth1 > a {
    text-decoration: none;
    padding: 5px 5px;
    display: block;
    color: inherit;
  }
  #frankenmenu-subnav-list .menu-item-depth1 > a:hover {
    text-decoration: underline;
  }
  #frankenmenu-subnav-list .sub-menu {
    display: none;
  }

  /* ---- Moved elements ---- */
  .frankenmoved {
    display: none;
  }
}
/** Mobile menu only
===================================*/
@media screen and (max-width: 61.9375em) {
  /* Prevents font-boosting of menu items on Android */
  #frankenmenu * {
    max-height: 999999px;
  }

  #wrap {
    margin-top: 48px;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    background-color: #ffffff;
  }

  #frankenmenu {
    top: 0;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    background-color: #ffffff;
    width: 70%;
    z-index: 0;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    padding-top: 48px;
    border-left: 1px solid #F0F0F0;
    z-index: 99;
  }
  #frankenmenu .container {
    position: relative;
    padding: 0;
    max-width: none;
    width: 100%;
    margin: 0;
    opacity: 0;
  }

  /* ---- Mobile header ---- */
  #mobile-header {
    background-color: #ffffff;
    height: 48px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    border: 1px solid #F0F0F0;
    transition: background-color 350ms ease;
  }
  #mobile-header .container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #mobile-menu-button {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none;
    border: none;
    padding: 0;
    text-indent: -9999px;
    width: 40px;
    height: 36px;
    margin-top: -2px;
    position: relative;
    background: none;
  }

  .mobile-menu-button-top,
.mobile-menu-button-middle,
.mobile-menu-button-bottom {
    height: 3px;
    background-color: #2b2b2b;
    left: 6px;
    animation-fill-mode: forwards;
    animation-duration: 400ms;
    position: absolute;
    display: block;
    right: 6px;
  }

  .mobile-menu-button-top {
    top: 8px;
  }

  .mobile-menu-button-middle {
    top: 17px;
  }

  .mobile-menu-button-bottom {
    top: 26px;
  }

  /* Animations active */
  .frankenmenu-mob-menu-animations .mobile-menu-button-top {
    animation-name: mobileMenuBtnTopClose;
  }

  .frankenmenu-mob-menu-animations .mobile-menu-button-middle {
    animation-name: mobileMenuBtnMiddleClose;
  }

  .frankenmenu-mob-menu-animations .mobile-menu-button-bottom {
    animation-name: mobileMenuBtnBottomClose;
  }

  /* Close button */
  .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-top {
    animation-name: mobileMenuBtnTopOpen;
  }

  .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-middle {
    animation-name: mobileMenuBtnMiddleOpen;
  }

  .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-bottom {
    animation-name: mobileMenuBtnBottomOpen;
  }

  @keyframes mobileMenuBtnTopClose {
    0% {
      transform: translate(0, 9px) rotate(45deg);
    }
    50%, 70% {
      transform: translate(0, 9px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes mobileMenuBtnTopOpen {
    0% {
      transform: translate(0, 0);
    }
    50%, 70% {
      transform: translate(0, 9px);
    }
    100% {
      transform: translate(0, 9px) rotate(45deg);
    }
  }
  @keyframes mobileMenuBtnBottomClose {
    0% {
      transform: translate(0, -9px) rotate(-45deg);
    }
    50%, 70% {
      transform: translate(0, -9px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes mobileMenuBtnBottomOpen {
    0% {
      transform: translate(0, 0);
    }
    50%, 70% {
      transform: translate(0, -9px);
    }
    100% {
      transform: translate(0, -9px) rotate(-45deg);
    }
  }
  @keyframes mobileMenuBtnMiddleClose {
    0% {
      opacity: 0;
    }
    50%, 70%, 100% {
      opacity: 1;
    }
  }
  @keyframes mobileMenuBtnMiddleOpen {
    0% {
      opacity: 1;
    }
    50%, 70%, 100% {
      opacity: 0;
    }
  }
  /* ---- All level menu items ---- */
  #frankenmenu-list,
#frankenmenu-list ul {
    width: 100%;
  }

  #frankenmenu-list {
    /* ---- First level menu ---- */
    /* ---- Submenus ---- */
    /* Depth differences */
  }
  #frankenmenu-list .menu-item {
    position: relative;
    border-bottom: 1px solid #F0F0F0;
    float: none;
    width: 100%;
  }
  #frankenmenu-list a {
    min-height: 40px;
    width: 100%;
    padding: 9px 64px 9px 16px;
    color: #636363;
  }
  #frankenmenu-list .menu-item.menu-current-item > a {
    background-color: #023578;
    color: #ffffff;
  }
  #frankenmenu-list .menu-item--member-login a {
    background-color: #2D96D3 !important;
    color: #ffffff;
    font-weight: bold;
  }
  #frankenmenu-list ul > .menu-item:last-child {
    border-bottom: none;
  }
  #frankenmenu-list .sub-menu {
    display: none;
  }
  #frankenmenu-list .menu-current-item-ancestor > .sub-menu {
    display: block;
  }
  #frankenmenu-list .menu-item-depth1 > a {
    padding-left: 16px;
    background-color: #ffffff;
  }
  #frankenmenu-list .menu-item-depth2 > a {
    padding-left: 24px;
  }
  #frankenmenu-list .menu-item-depth3 > a {
    padding-left: 32px;
  }
  #frankenmenu-list .menu-item-depth4 > a {
    padding-left: 40px;
  }
  #frankenmenu-list .menu-item-depth5 > a {
    padding-left: 48px;
  }
  #frankenmenu-list .menu-item-depth6 > a {
    padding-left: 56px;
  }

  /* Expand buttons */
  .submenu-toggle {
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border: none;
    width: 56px;
    background: none;
    height: 43px;
    display: block;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    padding: 0;
  }

  .submenu-toggle:before,
.submenu-toggle:after {
    content: " ";
    width: 12px;
    height: 3px;
    display: block;
    position: absolute;
    background-color: #636363;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 22px;
    transition: top 400ms ease, transform 400ms ease;
  }

  .submenu-toggle:before {
    transform: rotate(45deg) translateX(-5px);
  }

  .submenu-toggle:after {
    transform: rotate(-45deg) translateX(5px);
  }

  .menu-item-submenu-open > .submenu-toggle:before,
.menu-item-submenu-open > .submenu-toggle:after {
    top: 15px;
  }

  .menu-item-submenu-open > .submenu-toggle:before {
    transform: rotate(-45deg) translateX(-5px);
  }

  .menu-item-submenu-open > .submenu-toggle:after {
    transform: rotate(45deg) translateX(5px);
  }

  .menu-item.menu-current-item .submenu-toggle:before,
.menu-item.menu-current-item .submenu-toggle:after {
    background-color: #ffffff;
  }

  /* ---- Sub-navigation ---- */
  #frankenmenu-subnav-list {
    display: none;
  }

  /* ---- Menu positions ---- */
  /* Left */
  .frankenmenu-mobile-pos-left #frankenmenu {
    left: -70%;
  }

  .frankenmenu-mobile-pos-left.frankenmenu-mob-menu-visible #frankenmenu {
    left: 0;
  }

  .frankenmenu-mobile-pos-left #frankenmenu .container {
    transform: translate3d(-10%, 0, 0) scale(0.9);
  }

  .frankenmenu-mobile-pos-left.frankenmenu-mob-menu-visible #wrap {
    transform: translate3d(70%, 0, 0);
  }

  /* Right */
  .frankenmenu-mobile-pos-right #frankenmenu {
    right: -70%;
  }

  .frankenmenu-mobile-pos-right.frankenmenu-mob-menu-visible #frankenmenu {
    right: 0;
  }

  .frankenmenu-mobile-pos-right #frankenmenu .container {
    transform: translate3d(10%, 0, 0) scale(0.9);
  }

  .frankenmenu-mobile-pos-right.frankenmenu-mob-menu-visible #wrap {
    transform: translate3d(-70%, 0, 0);
  }

  .frankenmenu-mobile-pos-right #mobile-menu-button {
    float: right;
  }

  /* ---- Transitions ---- */
  #frankenmenu {
    transition: left 350ms ease, right 350ms ease, opacity 350ms ease;
  }

  #wrap {
    transition: transform 350ms ease;
  }

  #frankenmenu .container {
    transition: transform 350ms ease, opacity 350ms ease;
  }

  .js #frankenmenu {
    opacity: 0;
  }

  /* Menu open */
  .frankenmenu-mob-menu-visible #frankenmenu {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  .frankenmenu-mob-menu-visible #frankenmenu .container {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }

  /* ---- Moved elements ---- */
  .frankenmove {
    display: none;
  }

  /* ---- Megamenu ---- */
  .mega-menu-preview {
    display: none;
  }

  .mega-menu-preview-image {
    background-image: none !important;
  }

  .mega-menu-column-title {
    color: #ffffff;
    font-size: var(--body-font-size);
    padding: 3px 20px;
    margin: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  #frankenmenu-list .mega-menu {
    display: none;
  }

  #frankenmenu-list .mega-menu ul {
    display: block;
  }
}
.related-links {
  background-color: #001A44;
  background-image: url(../assets/deco-white-grid.svg);
  background-repeat: repeat-y;
  padding: 16px 16px 16px 32px;
  color: #ffffff;
  margin-bottom: 24px;
  /* - Depth 1 - */
  /* - Depth 2 - */
}
.related-links > *:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 22.5em) {
  .related-links {
    padding: 24px 24px 24px 40px;
  }
}
@media screen and (min-width: 48em) {
  .related-links {
    padding: 32px 32px 32px 48px;
  }
}
.related-links a {
  color: currentColor;
}
.related-links a:hover, .related-links a:focus, .related-links a:active {
  color: #2D96D3;
}
.related-links__title {
  font-size: 1.8rem;
  line-height: 24px;
  font-weight: normal;
  color: #2D96D3;
  margin: 0;
}
.related-links ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  /* Active / on / current */
}
.related-links ul li {
  line-height: 1.5;
}
.related-links ul a {
  font-family: "Roboto Condensed", Arial Narrow, sans-serif;
  text-decoration: none;
}
.related-links ul a:before, .related-links ul a:after {
  content: "";
}
.related-links ul li > a:hover, .related-links ul li > a:focus, .related-links ul li > a:active,
.related-links ul li.current-item > a:hover,
.related-links ul li.current-item > a:focus,
.related-links ul li.current-item > a:active {
  color: #2D96D3;
}
.related-links .depth1 li {
  display: block;
}
.related-links .depth1 > li > a {
  text-transform: uppercase;
  font-weight: 700;
  font-size: var(--h2-font-size);
  line-height: var(--h2-line-height);
  font-family: "Roboto Condensed", Arial Narrow, sans-serif;
  color: #ffffff;
  line-height: 1.5;
}
.related-links .depth2 {
  margin-left: 0.75em;
}
.related-links .depth2 > li {
  margin-top: 8px;
  margin-bottom: 8px;
}
.related-links .depth2 > li:last-child {
  margin-bottom: 0px;
}
.related-links .depth2 > li:nth-of-type(odd):last-child {
  margin-bottom: 4px;
}
.related-links .depth2 > li a {
  text-transform: none;
  font-weight: normal;
  font-size: 2.2rem;
}
.related-links .depth2 .depth2 a {
  font-size: 1.6rem;
}

/* ---- Forms ---- */
/* ---- Attach wrapper ---- */
.field-element-attach-wrapper {
  position: relative;
}

.field-element-attach-wrapper__button {
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  border-radius: 0;
  background: #2D96D3;
  border: none;
  outline: none;
  z-index: 1;
  cursor: pointer;
  min-width: 45px;
}

.field-element-attach-wrapper__button svg {
  vertical-align: middle;
}

.field-element-attach-wrapper__button:hover,
.field-element-attach-wrapper__button:active,
.field-element-attach-wrapper__button:focus {
  background-color: #022a5f;
}

.field-element-attach-wrapper .field-element .field-input .textbox,
.field-element-attach-wrapper .field-element .field-input select {
  padding-right: 55px;
}

/* White attachment wrapper */
.field-element-attach-wrapper--white .field-element-attach-wrapper__button {
  background-color: #d4d4d4;
}

.field-element-attach-wrapper--white .field-element-attach-wrapper__button:hover,
.field-element-attach-wrapper--white .field-element-attach-wrapper__button:active,
.field-element-attach-wrapper--white .field-element-attach-wrapper__button:focus {
  background-color: #b8b8b8;
}

.field-element-pill-wrapper {
  display: flex;
}
.field-element-pill-wrapper:focus-within {
  outline: #636363 auto 5px;
}
.field-element-pill-wrapper > *:first-child {
  flex: 1 1 auto;
  max-width: 100%;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.field-element-pill-wrapper > *:first-child input,
.field-element-pill-wrapper > *:first-child select,
.field-element-pill-wrapper > *:first-child textarea {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.field-element-pill-wrapper > *:first-child input:focus,
.field-element-pill-wrapper > *:first-child select:focus,
.field-element-pill-wrapper > *:first-child textarea:focus {
  outline: 1px dashed #b8b8b8;
}
.field-element-pill-wrapper > *:last-child {
  flex: 0 0 auto;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-top: calc( 1em * 1.2 + 8px);
}
.field-element-pill-wrapper .field-element--hidden-label + input[type=submit],
.field-element-pill-wrapper .field-element--hidden-label + button[type=submit] {
  margin-top: 0;
}
@media screen and (max-width: 30em) {
  .field-element-pill-wrapper input[type=submit],
.field-element-pill-wrapper button[type=submit] {
    padding-left: 1em;
    padding-right: 1em;
  }
}

.field-element {
  margin-bottom: var(--body-font-size);
}

.field-element--mb0 {
  margin-bottom: 0;
}

/* ---- Placeholders ---- */
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #9c9c9c;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #9c9c9c;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #9c9c9c;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #9c9c9c;
}

.field-element--dropdown--placeholder .field-input select {
  color: #9c9c9c;
}

/* ---- Auto-fill color ---- */
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: #d4d4d4;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #d4d4d4 inset;
}

input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  outline: #636363 auto 5px;
  background-color: #d4d4d4;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 80px #d4d4d4 inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* All form elements with preceding icon */
.field-element--datepicker .field-input .textbox,
.field-element--daterangepicker .field-input .textbox,
.field-element--datetimepicker .field-input .textbox,
.field-element--datetimerangepicker .field-input .textbox,
.field-element--money .field-input .textbox,
.field-element--timepicker .field-input .textbox {
  padding-left: 52px;
}

/* Clearfix all field-elements */
.field-element:before,
.field-element:after {
  content: " ";
  display: table;
}

.field-element:after {
  clear: both;
}

/* ---- form colour variants ---- */
/* -- default - grey -- */
.field-input input,
.field-input textarea,
.field-input select {
  border: var(--field-default-border);
  background-color: var(--field-default-background);
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  border-radius: 4px;
}

.field-input input:focus,
.field-input textarea:focus,
.field-input select:focus {
  outline: #636363 auto 5px;
}

/* -- white -- */
.field-element--white .field-input input,
.field-element--white .field-input textarea,
.field-element--white .field-input select {
  background-color: #ffffff;
}

/* ---- Disabled ---- */
.field-element--disabled,
.fieldset-input.fieldset-input--disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.field-element--disabled .textbox[disabled],
.field-element--disabled select[disabled],
.field-element--disabled button[disabled],
.field-element--disabled input[type=checkbox] + label,
.fieldset-input.fieldset-input--disabled input[type=checkbox] + label,
.field-element--disabled input[type=radio] + label,
.fieldset-input.fieldset-input--disabled input[type=radio] + label {
  cursor: not-allowed;
}

/* ---- Labels ---- */
/* ---- Labels ---- */
.field-label,
.fieldset__legend {
  font-weight: 700;
  margin-bottom: 8px;
  line-height: normal;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
}

.field-element--small .field-label,
.field-element--small .fieldset__legend {
  font-size: var(--centi-font-size);
}

.field-label.field-label--xsm {
  font-size: var(--milli-font-size);
}

/* Hidden labels */
.field-element--hidden-label .field-label,
.field-element--hidden-label .fieldset__legend {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.field-element--hidden-label .field-label.focusable:active, .field-element--hidden-label .field-label.focusable:focus,
.field-element--hidden-label .fieldset__legend.focusable:active,
.field-element--hidden-label .fieldset__legend.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/* ---- Required ---- */
.field-label__required {
  text-indent: -9999px;
  line-height: 0;
  display: inline-block;
}

.field-label__required:after {
  content: "*";
  text-indent: 0;
  display: block;
  line-height: inherit;
}

/* ---- Field helpers ---- */
.field-helper {
  font-weight: 400;
  font-size: var(--milli-font-size);
  margin-bottom: 1em;
}

.field-label .field-helper {
  margin-top: 0.3em;
  margin-bottom: 0;
}

/* ---- form style extenders ---- */
.field-element--totalselector__dropdown:before, .daterangepicker.opensright:before {
  position: absolute;
  top: -8px;
  left: 16px;
  display: inline-block;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #9c9c9c;
  border-left: 8px solid transparent;
  border-bottom-color: #9c9c9c;
  content: "";
}

.field-element--totalselector__dropdown:after, .daterangepicker.opensright:after {
  position: absolute;
  top: -7px;
  left: 17px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #fff;
  border-left: 7px solid transparent;
  content: "";
}

/* ---- Field errors ---- */
.field-element.field-element--error .field-input .textbox,
.field-element.field-element--error .field-input select {
  background-color: #fde8e8;
  border-color: #fcd6d5;
}

.field-element.field-element--warning .field-input .textbox,
.field-element.field-element--warning .field-input select {
  background-color: #ffebe4;
  border-color: #ffddd0;
}

.field-error__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.field-error__list__item {
  padding: 10px 20px;
  margin: 11px 0;
  background: #C32C2A;
  border-left: 4px solid #9E0E0B;
  color: #FFF !important;
}

.field-element--colorpicker .textbox.colorpicker {
  padding: 2px 8px;
  height: 40px;
  width: 48px;
}

.field-element--datepicker .field-input,
.field-element--daterangepicker .field-input {
  position: relative;
}

.field-element--datepicker .field-input:before,
.field-element--daterangepicker .field-input:before {
  content: " ";
  display: block;
  position: absolute;
  left: 2px;
  top: calc(50% - 12px);
  width: 40px;
  background-image: url(../assets/icon-system/icon_form_datepicker.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  border-right: 1px solid #9c9c9c;
}

/* Container Appearance */
.daterangepicker {
  position: absolute;
  background: #fff;
  top: 100px;
  left: 20px;
  padding: 4px;
  margin-top: 2px;
  border-radius: 4px;
  width: 278px;
}

.daterangepicker.opensleft:before {
  position: absolute;
  top: -7px;
  right: 9px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #9c9c9c;
  border-left: 7px solid transparent;
  border-bottom-color: #9c9c9c;
  content: "";
}

.daterangepicker.opensleft:after {
  position: absolute;
  top: -6px;
  right: 10px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  content: "";
}

.daterangepicker.openscenter:before {
  position: absolute;
  top: -7px;
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #9c9c9c;
  border-left: 7px solid transparent;
  border-bottom-color: #9c9c9c;
  content: "";
}

.daterangepicker.openscenter:after {
  position: absolute;
  top: -6px;
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  content: "";
}

.daterangepicker.dropup {
  margin-top: -5px;
}

.daterangepicker.dropup:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #9c9c9c;
}

.daterangepicker.dropup:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #ffffff;
}

.daterangepicker.dropdown-menu {
  max-width: none;
  z-index: 3000;
  display: none;
  border: 1px solid #9c9c9c;
}

.daterangepicker.single .ranges, .daterangepicker.single .calendar {
  float: none;
}

.daterangepicker .calendar {
  display: none;
  max-width: 270px;
  margin: 4px;
}

.daterangepicker.show-calendar .calendar {
  display: block;
}

.daterangepicker .calendar.single .calendar-table {
  border: none;
}

/* Calendars */
.daterangepicker .calendar th, .daterangepicker .calendar td {
  white-space: nowrap;
  text-align: center;
  min-width: 32px;
  border: 1px solid #d4d4d4;
}

.daterangepicker .calendar-table {
  border: 1px solid #b8b8b8;
  padding: 4px;
  border-radius: 4px;
  background: #ffffff;
}

.daterangepicker table {
  width: 100%;
  margin: 0;
  border: none;
}

.daterangepicker td,
.daterangepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  white-space: nowrap;
  padding: 2px;
}

.daterangepicker td,
.daterangepicker th.available {
  cursor: pointer;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  color: #636363;
  background: #F0F0F0;
  border-color: #d4d4d4;
}

.daterangepicker td.off.in-range {
  background-color: #F0F0F0;
}

.daterangepicker td.off.active {
  background-color: #7f7f7f;
  color: #ffffff;
}

.daterangepicker td.disabled,
.daterangepicker option.disabled {
  color: #636363;
  cursor: not-allowed;
  text-decoration: line-through;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background: #96cbe9;
}

.daterangepicker td.in-range {
  background: #abd5ee;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #2D96D3;
  color: #ffffff;
}

.daterangepicker td.week, .daterangepicker th.week {
  font-size: 80%;
  color: #9c9c9c;
}

.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}

.daterangepicker select.monthselect::-ms-expand,
.daterangepicker select.yearselect::-ms-expand {
  display: none;
}

.daterangepicker select.yearselect,
.daterangepicker select.monthselect,
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
  margin-bottom: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 62px;
  background-color: #ffffff;
  outline: none;
  border-radius: 4px;
  font-size: 1.4rem;
  line-height: 1.2;
  padding: 5px 23px 5px 8px;
  border: 1px solid #9c9c9c;
  background-image: url(../assets/icon-system/icon_form_arrow-down.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right;
}

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 40%;
}

.daterangepicker select.yearselect {
  width: 44%;
}

.daterangepicker th.month {
  width: auto;
}

/* Previous/Next buttons */
.daterangepicker th.prev,
.daterangepicker th.next {
  padding: 1px 2px;
}

.daterangepicker th.prev .daterangepicker-prev-icon,
.daterangepicker th.next .daterangepicker-next-icon {
  width: 12px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
  display: block;
  margin: 0 auto;
}

.daterangepicker th.next .daterangepicker-next-icon {
  background-image: url(../assets/icon-system/icon_form_arrow-right.svg);
}

.daterangepicker th.prev .daterangepicker-prev-icon {
  background-image: url(../assets/icon-system/icon_form_arrow-left.svg);
}

.daterangepicker th.prev,
.daterangepicker th.next,
.daterangepicker th.month,
.daterangepicker .calendar-table thead tr:first-child th {
  border: none;
  background: none;
}

.daterangepicker th.prev:hover,
.daterangepicker th.next:hover {
  background: none;
  opacity: 0.8;
}

.daterangepicker .calendar-table thead tr:nth-child(2) th {
  background-color: #F0F0F0;
}

/* Text Input Above Each Calendar */
.daterangepicker .input-mini {
  border: 1px solid #9c9c9c;
  border-radius: 4px;
  color: #474747;
  display: block;
  height: 32px;
  line-height: 32px;
  vertical-align: middle;
  margin: 0 0 4px 0;
  padding: 0 16px;
  width: 100%;
}

.daterangepicker .input-mini.active {
  border: 1px solid #EB8A01;
  background-color: #F0F0F0;
}

.daterangepicker .input-mini:focus {
  outline: 4px auto #EB8A01;
  outline-offset: -2px;
}

.daterangepicker .daterangepicker_input i {
  position: absolute;
  left: 8px;
  top: 7px;
  color: #474747;
}

.daterangepicker .daterangepicker_input {
  position: relative;
}

/* Time Picker */
.daterangepicker .calendar-time {
  text-align: center;
  margin: 5px auto;
  line-height: 30px;
  position: relative;
}

.daterangepicker .calendar-time select.disabled {
  color: #9c9c9c;
  cursor: not-allowed;
}

/* Predefined Ranges */
.daterangepicker .ranges {
  font-size: 11px;
  float: none;
  margin: 4px;
  text-align: left;
}

.daterangepicker .applyBtn {
  background-color: #EB8A01;
  color: #ffffff;
}

.daterangepicker .applyBtn:hover,
.daterangepicker .applyBtn:focus {
  background-color: #b86c01;
  color: #ffffff;
}

.daterangepicker .cancelBtn {
  background-color: #b8b8b8;
  color: #0f0f0f;
}

.daterangepicker .cancelBtn:hover,
.daterangepicker .cancelBtn:focus {
  background-color: #9f9f9f;
  color: #0f0f0f;
}

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto 12px;
  padding: 0;
  width: 100%;
}

.daterangepicker .ranges li {
  font-size: 1.3rem;
  background: #d4d4d4;
  border: 1px solid #F0F0F0;
  color: #0f0f0f;
  padding: 4px 12px 3px;
  margin-bottom: 7px;
  border-radius: 5px;
  cursor: pointer;
  line-height: 1.2;
}

.daterangepicker .ranges li.active, .daterangepicker .ranges li:hover {
  background: #2D96D3;
  border: 1px solid #2D96D3;
  color: #ffffff;
}

@supports (display: grid) {
  .range_inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 8px;
  }
}
/* BP Smaller */
@media screen and (min-width: 35em) {
  .daterangepicker {
    width: auto;
  }

  .daterangepicker .ranges ul {
    width: 160px;
  }

  .daterangepicker.single .ranges ul {
    width: 100%;
  }

  .daterangepicker .calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .daterangepicker .calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .daterangepicker .calendar.left {
    clear: left;
    margin-right: 0;
  }

  .daterangepicker.single .calendar.left {
    clear: none;
  }

  .daterangepicker.single .ranges,
.daterangepicker.single .calendar {
    float: left;
  }

  .daterangepicker .calendar.right {
    margin-left: 0;
  }

  .daterangepicker .left .daterangepicker_input {
    padding-right: 12px;
  }

  .daterangepicker .calendar.left .calendar-table {
    padding-right: 12px;
  }

  .daterangepicker .ranges,
.daterangepicker .calendar {
    float: left;
  }
}
/* BP small */
@media screen and (min-width: 48em) {
  .daterangepicker .ranges {
    width: auto;
    float: left;
  }

  .daterangepicker .calendar.left {
    clear: none;
  }
}
/* - Date/time picker - */
.field-element--datetimepicker .field-input,
.field-element--datetimerangepicker .field-input {
  position: relative;
}

.field-element--datetimepicker .field-input:before,
.field-element--datetimerangepicker .field-input:before {
  content: " ";
  display: block;
  position: absolute;
  left: 2px;
  top: calc(50% - 12px);
  width: 40px;
  background-image: url(../assets/icon-system/icon_form_datetimepicker.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  border-right: 1px solid #9c9c9c;
}

:root {
  --input-diameter: 1.5em;
  --input-small-diameter: 1em;
}

/* fieldset styles */
fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

.field-element--fieldset-columns .field-element__input-set {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  column-gap: 16px;
}

/* Hide checkbox and radio field element) */
.field-element input[type=checkbox],
.field-element input[type=radio] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.field-element input[type=checkbox].focusable:active, .field-element input[type=checkbox].focusable:focus,
.field-element input[type=radio].focusable:active,
.field-element input[type=radio].focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.field-element input[type=checkbox] + label,
.field-element input[type=radio] + label,
.field-element--small input[type=checkbox] + label,
.field-element--small input[type=radio] + label {
  padding-left: 32px;
  position: relative;
  cursor: pointer;
  display: inline-block;
}

/* -- Field set pseudo elements -- */
.field-element input[type=checkbox] + label:before,
.field-element input[type=radio] + label:before {
  content: " ";
  display: inline-block;
  position: relative;
  border-radius: 4px;
  border: var(--field-default-border);
  background-color: var(--field-default-background);
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  top: 0.15em;
  margin-left: -32px;
}

.field-element--small input[type=checkbox] + label:before,
.field-element--small input[type=radio] + label:before {
  width: var(--input-small-diameter);
  height: var(--input-small-diameter);
}

/* Radio button (pseudo element) */
.field-element input[type=radio] + label:before,
.field-element--small input[type=radio] + label:before {
  border-radius: 100%;
}

/* -- Field set pseudo elements : checked -- */
.field-element input[type=checkbox]:checked + label:after,
.field-element input[type=radio]:checked + label:after,
.field-element--small input[type=checkbox]:checked + label:after,
.field-element--small input[type=radio]:checked + label:after {
  content: " ";
  position: absolute;
  left: 1px;
  top: 0.6em;
}

/* Checkbox box checked (pseudo element) */
.field-element input[type=checkbox]:checked + label:after {
  content: " ";
  display: inline-block;
  position: relative;
  border-radius: 4px;
  border: var(--field-default-border);
  background-color: var(--field-default-background);
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  background-image: url(../assets/icon-system/icon_form_tick-white.svg);
  background-color: #0192d0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 90%;
}

.field-element--small input[type=checkbox]:checked + label:after {
  width: var(--input-small-diameter);
  height: var(--input-small-diameter);
}

/* Radio dot (pseudo element) */
.field-element input[type=radio]:checked + label:after {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(var(--input-small-diameter) - 1em);
  height: calc(var(--input-small-diameter) - 1em);
  border-radius: 100%;
  background: #ffffff;
}

.field-element--small input[type=radio]:checked + label:after {
  width: calc(var(--input-small-diameter) - 4px);
  height: calc(var(--input-small-diameter) - 4px);
}

/* White */
.field-element--white input[type=checkbox] + label:before,
.field-element--white input[type=radio] + label:before {
  background-color: #ffffff;
}

/* input focus state */
.fieldset--multiradio:focus-within {
  outline: #d4d4d4 dashed 1px;
}

.field-element input[type=checkbox]:focus + label {
  outline: #636363 auto 5px;
  outline-offset: -3px;
}

/* ---- Field errors ---- */
.field-element.field-element--error input[type=checkbox] + label:before,
.field-element.field-element--error input[type=radio] + label:before {
  background-color: #fde8e8;
  border-color: #fcd6d5;
}

.field-element.field-element--warning input[type=checkbox] + label:before,
.field-element.field-element--warning input[type=radio] + label:before {
  background-color: #ffebe4;
  border-color: #ffddd0;
}

/* ---- Field errors ---- */
.field-element.field-element--error label {
  color: #9E0E0B;
}
.field-element.field-element--error input[type=checkbox] + label:before,
.field-element.field-element--error input[type=radio] + label:before {
  background-color: #fde8e8;
  border-color: #fcd6d5;
}
.field-element.field-element--error .pseudo-input {
  background-color: #fde8e8;
  border-color: #fcd6d5;
}

.field-element.field-element--warning label {
  color: #A72D00;
}
.field-element.field-element--warning input[type=checkbox] + label:before,
.field-element.field-element--warning input[type=radio] + label:before {
  background-color: #ffebe4;
  border-color: #ffddd0;
}
.field-element.field-element--warning .pseudo-input {
  background-color: #ffebe4;
  border-color: #ffddd0;
}

.field-element--money .field-input {
  position: relative;
}

.field-element--money .field-input .money-symbol:before {
  content: "¤";
  /* Generic currency sign */
  position: absolute;
  display: block;
  left: 2px;
  top: calc(50% - 14px);
  color: #474747;
  border-right: 1px solid #9c9c9c;
  font-weight: bold;
  font-size: 120%;
  width: 40px;
  text-align: center;
}

.field-element--money .field-input .money-symbol--dollar:before {
  content: "$";
  /* Dollars */
}

.field-element--money .field-input .money-symbol--pound:before {
  content: "£";
  /* Pounds */
}

.field-element--money .field-input .money-symbol--yen:before {
  content: "¥";
  /* Yen */
}

.field-element--money .field-input .money-symbol--indian_rupee:before {
  content: "₹";
  /* Indian rupee */
}

.field-element--range .field-input .textbox {
  background: none;
  padding: 0;
  border-radius: 0;
  border: none;
  background: none !important;
}

.field-element--range .field-input input[type=range] + div {
  color: #474747;
  font-weight: bold;
}

.field-element--range .field-input input[type=range]:focus + div {
  color: #EB8A01;
  font-weight: bold;
}

.field-element--range input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  /* Otherwise white in Chrome */
  margin: 0;
}

.field-element--range input[type=range]:focus {
  outline: none;
}

.field-element--range input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  box-shadow: none;
  background: #9c9c9c;
  border-radius: 4px;
  border: none;
}

.field-element--range input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  box-shadow: 1px 1px 2px #2e2e2e;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: #474747;
  cursor: pointer;
  margin-top: -14px;
  transition: background-color 250ms ease-in-out, box-shadow 250ms ease-in-out;
}

.field-element--range input[type=range]:focus::-webkit-slider-runnable-track {
  background: #9c9c9c;
}

.field-element--range input[type=range]:focus::-webkit-slider-thumb {
  background: #EB8A01;
  box-shadow: 2px 2px 4px #474747;
}

.field-element--range input[type=range]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  box-shadow: none;
  background: #b8b8b8;
  border-radius: 4px;
  border: none;
}

.field-element--range input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 2px #2e2e2e;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: #474747;
  cursor: pointer;
}

.field-element--range input[type=range]::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 18px 0;
  color: transparent;
}

.field-element--range input[type=range]::-ms-fill-lower {
  background: #b8b8b8;
  border: none;
  border-radius: 4px;
  box-shadow: none;
}

.field-element--range input[type=range]::-ms-fill-upper {
  background: #b8b8b8;
  border: none;
  border-radius: 4px;
  box-shadow: none;
}

.field-element--range input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 2px #2e2e2e;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: #474747;
  cursor: pointer;
}

.field-element--range input[type=range]:focus::-ms-fill-lower {
  background: #b8b8b8;
}

.field-element--range input[type=range]:focus::-ms-fill-upper {
  background: #b8b8b8;
}

.field-element--range input[type=text] {
  display: none;
}

/* IE hack fix -
if not IE, apply these styles
has nothing to do with (display:block)
and everything to do with IE not supporting @supports */
@supports (display: block) {
  .field-element--range input[type=range] {
    margin: 12.8px 0;
  }
}
.field-input select {
  -moz-appearance: none;
  background-image: url(../assets/icon-system/icon_form_arrow-down.svg), url(../assets/icon-system/form_bg-fill.svg);
  background-repeat: no-repeat, repeat-Y;
  background-position: center right 8px, center right;
  background-size: 24px, 40px;
  padding-right: 52px;
}

.field-input select[multiple] {
  background-image: none;
  padding-right: 20px;
}

.field-input select::-ms-expand {
  display: none;
}

/* Multiple selects */
.field-element--select--multiple select {
  padding: 8px;
  background-image: none;
}

.field-element--select--multiple option {
  padding: 8px;
}

.field-element--range .field-input .textbox {
  background: none;
  padding: 0;
  border-radius: 0;
  border: none;
  background: none !important;
}

.field-element--range .field-input input[type=range] + div {
  color: #474747;
  font-weight: bold;
}

.field-element--range .field-input input[type=range]:focus + div {
  color: #EB8A01;
  font-weight: bold;
}

.field-element--range input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  /* Otherwise white in Chrome */
  margin: 0;
}

.field-element--range input[type=range]:focus {
  outline: none;
}

.field-element--range input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  box-shadow: none;
  background: #9c9c9c;
  border-radius: 4px;
  border: none;
}

.field-element--range input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  box-shadow: 1px 1px 2px #2e2e2e;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: #474747;
  cursor: pointer;
  margin-top: -14px;
  transition: background-color 250ms ease-in-out, box-shadow 250ms ease-in-out;
}

.field-element--range input[type=range]:focus::-webkit-slider-runnable-track {
  background: #9c9c9c;
}

.field-element--range input[type=range]:focus::-webkit-slider-thumb {
  background: #EB8A01;
  box-shadow: 2px 2px 4px #474747;
}

.field-element--range input[type=range]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  box-shadow: none;
  background: #b8b8b8;
  border-radius: 4px;
  border: none;
}

.field-element--range input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 2px #2e2e2e;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: #474747;
  cursor: pointer;
}

.field-element--range input[type=range]::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 18px 0;
  color: transparent;
}

.field-element--range input[type=range]::-ms-fill-lower {
  background: #b8b8b8;
  border: none;
  border-radius: 4px;
  box-shadow: none;
}

.field-element--range input[type=range]::-ms-fill-upper {
  background: #b8b8b8;
  border: none;
  border-radius: 4px;
  box-shadow: none;
}

.field-element--range input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 2px #2e2e2e;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: #474747;
  cursor: pointer;
}

.field-element--range input[type=range]:focus::-ms-fill-lower {
  background: #b8b8b8;
}

.field-element--range input[type=range]:focus::-ms-fill-upper {
  background: #b8b8b8;
}

.field-element--range input[type=text] {
  display: none;
}

/* IE hack fix -
if not IE, apply these styles
has nothing to do with (display:block)
and everything to do with IE not supporting @supports */
@supports (display: block) {
  .field-element--range input[type=range] {
    margin: 12.8px 0;
  }
}
.field-input .textbox,
.field-input select,
.field-element--fileselector .fs-preview-wrapper {
  display: block;
  width: 100%;
  outline: none;
  line-height: normal;
  -webkit-appearance: none;
  line-height: var(--body-line-height);
  padding: var(--field-padding-vertical) var(--field-padding-horizontal);
}

.field-element--small .field-input .textbox,
.field-element--small .field-input select,
.field-element--small .field-element--fileselector .fs-preview-wrapper {
  display: block;
  width: 100%;
  outline: none;
  line-height: normal;
  -webkit-appearance: none;
  line-height: var(--body-line-height);
  font-size: var(--centi-font-size);
  padding: var(--field-padding-vertical-sm) var(--field-padding-horizontal-sm);
}

/* time picker */
.field-element--timepicker .field-input {
  position: relative;
}

.field-element--timepicker .field-input:before {
  content: " ";
  display: block;
  position: absolute;
  left: 2px;
  top: calc(50% - 12px);
  width: 40px;
  background-image: url(../assets/icon-system/icon_form_timepicker.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  border-right: 1px solid #9c9c9c;
}

/* Timepicker modal */
.ui-timepicker {
  display: block;
  position: absolute;
  height: 200px;
  overflow: auto;
  z-index: 9999 !important;
}

.ui-timepicker-standard {
  background-color: #ffffff;
  border: 1px solid #9c9c9c;
  display: block;
  margin: 4px 0 0;
  padding: 6px;
  list-style: none outside none;
  border-radius: 4px;
}

.ui-timepicker-standard a {
  color: #0f0f0f;
}

.ui-timepicker-standard .ui-state-hover {
  background-color: #d4d4d4;
}

.ui-timepicker-standard .ui-menu-item {
  clear: left;
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
}

.ui-timepicker-standard .ui-menu-item a {
  display: block;
  padding: 6px 12px;
  line-height: 1.5;
  text-decoration: none;
  border-radius: 4px;
}

.ui-timepicker-hidden {
  display: none;
}

.field-element--totalselector {
  position: relative;
}

.field-element--totalselector .field-input .total-selector__output {
  cursor: pointer;
  background-image: url(../assets/icon-system/icon_form_arrow-down.svg), url(../assets/icon-system/form_bg-fill.svg);
  background-repeat: no-repeat, repeat-Y;
  background-position: center right 8px, center right;
  background-size: 24px, 40px;
  padding-right: 52px;
}

.field-element--totalselector__dropdown {
  position: absolute;
  background: #ffffff;
  top: 100%;
  left: 0;
  right: 0;
  padding: 20px 24px;
  margin-top: 2px;
  border-radius: 4px;
  z-index: 800;
  border: 1px solid #9c9c9c;
  display: none;
  white-space: nowrap;
}

.field-element--totalselector.field-element--totalselector--active .field-element--totalselector__dropdown {
  display: block;
}

.total-selector__dropdown__field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 0 16px;
}

.total-selector__dropdown__field:last-child {
  margin-bottom: 0;
}

.total-selector__dropdown__field__labels {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.total-selector__dropdown__field__buttons {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.total-selector__dropdown__field__button {
  -webkit-appearance: none;
  border: 1px solid #7f7f7f;
  border-radius: 4px;
  outline: none;
  background: none;
  padding: 0;
  height: 32px;
  width: 32px;
  color: #636363;
  cursor: pointer;
  font-size: 1.4rem;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: center center;
}

.total-selector__dropdown__field__button.total-selector__dropdown__field__button--decrease {
  background-image: url(../assets/icon-system/icon_form_minus.svg);
  background-position: left 8px -1 center;
}

.total-selector__dropdown__field__button.total-selector__dropdown__field__button--increase {
  background-image: url(../assets/icon-system/icon_form_plus.svg);
  background-position: right 8px -1 center;
}

.total-selector__dropdown__field__button:focus {
  color: #636363;
  border-color: #636363;
  background-color: #F0F0F0;
}

.total-selector__dropdown__field__button:hover {
  color: #636363;
  border-color: #636363;
}

/* Min / max */
.total-selector__dropdown__field__button.total-selector__dropdown__field__button--min,
.total-selector__dropdown__field__button.total-selector__dropdown__field__button--max {
  opacity: 0.5;
  pointer-events: none;
  color: #636363;
  border-color: #b8b8b8;
}

.total-selector__dropdown__field__total {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: 4px 6px;
  width: 50px;
  text-align: center;
  font-size: 1.8rem;
}

.total-selector__dropdown__field__labels p {
  display: block;
  margin-right: 8px;
}

.total-selector__dropdown__field__labels__title {
  margin: 0;
  font-size: 1.6rem;
}

.total-selector__dropdown__field__labels__helper {
  margin: 0;
  font-size: var(--milli-font-size);
}

.field-element--totalselector__fields {
  display: none;
}

/* Done button */
.total-selector__dropdown__close__button-wrap {
  text-align: right;
  padding-top: 10px;
}

/* BP Extra small */
@media screen and (min-width: 35em) {
  .field-element--totalselector__dropdown {
    right: auto;
    min-width: 320px;
  }

  .total-selector__dropdown__field__labels__helper {
    font-size: var(--body-font-size);
    line-height: 1;
  }

  .total-selector__dropdown__field {
    margin: 0 0 24px;
  }
}
/* BP Small */
@media screen and (min-width: 48em) {
  /* Don't really need this close button above tablet */
  .total-selector__dropdown__close__button-wrap {
    display: none;
  }
}
.field-element--upload {
  position: relative;
  cursor: pointer;
}
.field-element--upload:hover {
  cursor: pointer;
}
.no-js .field-element--upload .field-label__click-area {
  display: none;
}
.field-element--upload .field-label {
  position: relative;
  display: block;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 0;
  transition: grid-template-columns 250ms ease-in-out;
}
.field-element--upload .field-label label {
  position: relative;
}
.field-element--upload .field-label__click-area {
  font-size: 1.4rem;
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  right: 0;
  cursor: pointer;
  padding-left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.field-element--upload .field-label__click-area:before {
  cursor: pointer;
  content: "Select a file";
  margin-right: 16px;
  padding: 16px;
  min-width: 88px;
  background: #023578;
  border-radius: 4px;
  color: #ffffff;
}
.field-element--upload .field-label__click-area__status {
  cursor: pointer;
  flex: 1 1 auto;
  content: "No file selected";
  border: 2px solid #d4d4d4;
  border-radius: 4px;
  background-color: #F0F0F0;
  padding: 8px 16px;
  height: 48px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.field-element--upload .field-label__click-area:hover {
  cursor: pointer;
}
.field-element--upload.field-element--upload-has-val .field-label {
  grid-template-columns: auto 48px;
}
.no-js .field-element--upload .field-input {
  border: 2px solid #d4d4d4;
  background-color: #F0F0F0;
  border-radius: 4px;
}
.field-element--upload .field-input input {
  display: block;
  padding: 8px;
  min-height: calc(48px + 1px);
  width: 100%;
}
.js .field-element--upload .field-input input {
  background-color: transparent;
  color: transparent;
}
.js .field-element--upload .field-input input::-webkit-file-upload-button {
  opacity: 0;
}
.field-element--upload .field-clear-btn {
  background: url(../images/icon-system/icon_form_cross.svg) no-repeat center center;
  background-size: 32px auto;
  display: block;
  width: 0;
  height: 48px;
  position: absolute;
  bottom: 0;
  right: 0;
  text-indent: -100vw;
  opacity: 0.25;
  transition: opacity 250ms ease-in-out, width 250ms ease-in-out;
}
.no-js .field-element--upload .field-clear-btn {
  display: none;
}
.field-element--upload .field-clear-btn:hover {
  opacity: 1;
}
.field-element--upload.field-element--upload-has-val .field-clear-btn {
  width: 48px;
}

/* ---- white ---- */
.no-js .field-element--white.field-element--upload .field-input {
  border: 2px solid #d4d4d4;
  background-color: #ffffff;
  border-radius: 4px;
}
.field-element--white.field-element--upload .field-label__click-area__status {
  border: 2px solid #d4d4d4;
  background-color: #ffffff;
}

/* ---- Error ---- */
.field-element--upload.field-element--error .field-label__click-area__status {
  border-color: #c32c2a;
  background-color: #fde8e8;
}
.no-js .field-element--upload.field-element--error .field-input {
  border-color: #c32c2a;
  background-color: #fde8e8;
}

.submit-bar {
  text-align: right;
  background: #d4d4d4;
  padding: 16px;
  margin: 16px 0;
}

.sidebar .submit-bar {
  padding: 12px 0;
}

.submit-bar a {
  margin-right: 20px;
}

.field-element--button .button {
  padding-top: 14px;
  padding-bottom: 14px;
}
@media screen and (min-width: 48em) {
  .field-element--button .button {
    padding-top: 14px;
    padding-bottom: 14px;
  }
}

.site-search-form {
  margin: 0 0 40px;
}

.site-search-form--header {
  margin: 0;
}

.search-result {
  border-top: 1px solid #9c9c9c;
  margin: 20px 0;
  padding: 20px 0;
}

.search-result p:last-child {
  margin-bottom: 0;
}

.g-recaptcha {
  padding-bottom: 15px;
}

.field-element--upload .field-input input[type=file] {
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: transparent;
}

input[type=file]::-webkit-file-upload-button {
  padding: 4px 8px;
  border: 1px solid #9c9c9c;
  border-radius: 4px;
  background-color: #F0F0F0;
  transition: border-color 250ms ease-in-out, background-color 250ms ease-in-out;
  outline: none;
}

input[type=file]:hover::-webkit-file-upload-button {
  background-color: #d4d4d4;
  border-color: #838383;
}

input[type=file]:focus::-webkit-file-upload-button {
  background-color: #d4d4d4;
  outline: none;
}

input[type=file]:active::-webkit-file-upload-button {
  outline: none;
  background-color: #b8b8b8;
}

input[type=file]:focus {
  outline: #636363 auto 5px;
}

input[type=file i]:focus {
  outline-offset: 0;
}

/* ---- white ---- */
.field-element--white input[type=file]::-webkit-file-upload-button {
  background-color: #ffffff;
}

.field-element--white input[type=file]:hover::-webkit-file-upload-button {
  background-color: #d4d4d4;
  border-color: #838383;
}

.field-element--white input[type=file]:focus::-webkit-file-upload-button {
  background-color: #d4d4d4;
}

.field-element--white input[type=file]:active::-webkit-file-upload-button {
  background-color: #b8b8b8;
}

.field-element--upload-inline {
  background-color: #F0F0F0;
  padding: 16px 16px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
}
.field-element--upload-inline label {
  display: block;
  cursor: pointer;
  font-weight: bold;
}
.field-element--upload-inline label:after {
  content: "select";
  font-weight: normal;
  background: #2D96D3;
  color: #ffffff;
  padding: 4px 16px;
  border-radius: 4px;
  margin: 0 0 0 16px;
  transition: background-color 250ms ease-in-out;
}
.field-element--upload-inline label:hover:after {
  background-color: #1f6994;
}
.field-element--upload-inline input:focus + label,
.field-element--upload-inline input:active + label {
  outline: #636363 auto 5px;
}
.field-element--upload-inline input[type=file] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

:root {
  --gutter-width: 1rem;
  --outer-margin: 2rem;
  --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
  --half-gutter-width: calc((var(--gutter-width) * 0.5));
}

/* -----------------------------
Layout
----------------------------- */
.freeform-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: var(--gutter-compensation, -0.5rem);
  margin-left: var(--gutter-compensation, -0.5rem);
}
@media screen and (min-width: 35em) {
  .freeform-row {
    flex-wrap: nowrap;
  }
}
.freeform-row .freeform-column {
  flex: 1 1 100%;
  padding: 10px 0;
  margin: 0 var(--half-gutter-width, 0.5rem);
  box-sizing: border-box;
}
@media screen and (min-width: 35em) {
  .freeform-row .freeform-column {
    flex: 1 0 auto;
  }
}
.freeform-row .freeform-column-only-label {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

/* -----------------------------
Fields and Labels
----------------------------- */
.freeform-input[type=text], .freeform-input[type=number], .freeform-input[type=email] {
  display: block;
  width: 100%;
  outline: none;
  line-height: normal;
  -webkit-appearance: none;
  line-height: var(--body-line-height);
  padding: var(--field-padding-vertical) var(--field-padding-horizontal);
  border: var(--field-default-border);
  background-color: var(--field-default-background);
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
}
.freeform-input[type=checkbox], .freeform-input[type=radio] {
  margin-right: 0.5em;
}

select.freeform-input {
  display: block;
  width: 100%;
  outline: none;
  line-height: normal;
  -webkit-appearance: none;
  line-height: var(--body-line-height);
  padding: var(--field-padding-vertical) var(--field-padding-horizontal);
  border: var(--field-default-border);
  background-color: var(--field-default-background);
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  -moz-appearance: none;
  background-image: url(../assets/icon-system/icon_form_arrow-down.svg), url(../assets/icon-system/form_bg-fill.svg);
  background-repeat: no-repeat, repeat-Y;
  background-position: center right 8px, center right;
  background-size: 24px, 40px;
  padding-right: 52px;
}

textarea.freeform-input {
  display: block;
  width: 100%;
  outline: none;
  line-height: normal;
  -webkit-appearance: none;
  line-height: var(--body-line-height);
  padding: var(--field-padding-vertical) var(--field-padding-horizontal);
  border: var(--field-default-border);
  background-color: var(--field-default-background);
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
}

.freeform-label {
  font-weight: 700;
  margin-bottom: 8px;
  line-height: normal;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
}
.freeform-label.freeform-input-only-label {
  margin-bottom: 0;
  margin-top: calc(1em + 8px);
}

.freeform-required:after {
  content: "*";
  display: inline-block;
  line-height: inherit;
}

.freeform-instructions {
  font-size: var(--centi-font-size);
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  margin-top: 0.25em;
}

.freeform-note {
  color: #7f7f7f;
}

.flatpickr-calendar {
  font-size: var(--centi-font-size);
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
}

/* -----------------------------
Alignment
----------------------------- */
.freeform-column-content-align-left {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.freeform-column-content-align-right {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.freeform-column-content-align-spread {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.freeform-form .form__element p:last-of-type {
  margin-bottom: 0;
}

/* -----------------------------
Multi page forms
----------------------------- */
.freeform-pages {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.freeform-pages li {
  flex: 0 1 100%;
  display: block;
  height: 50px;
  line-height: 50px;
  vertical-align: middle;
  transition: all 0.15s ease-in-out;
  cursor: default;
  text-decoration: none;
  text-align: center;
  transition: all 0.25s;
  border: 0;
  outline: none;
  background-color: #ffffff;
  color: #636363;
  margin-bottom: -1px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  text-decoration: none;
}
.freeform-pages li:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
@media screen and (min-width: 62em) {
  .freeform-pages li:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: initial;
    border-bottom-left-radius: 0.25rem;
  }
}
.freeform-pages li:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
@media screen and (min-width: 62em) {
  .freeform-pages li:last-child {
    margin-right: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: initial;
    border-top-right-radius: 0.25rem;
  }
}
.freeform-pages li .freeform-pages li.active {
  background-color: #023578;
  color: #ffffff;
}

.ff-form-success p {
  margin: 0 0 8px;
  padding: 8px 16px;
  text-align: left;
  background: #26883A;
  border-left: 4px solid #0E6F22;
  color: #FFF !important;
}
.ff-form-success p a {
  color: inherit;
}

.ff-form-errors p {
  margin: 0 0 8px;
  padding: 8px 16px;
  text-align: left;
  background: #C32C2A;
  border-left: 4px solid #9E0E0B;
  color: #FFF !important;
}
.ff-form-errors p a {
  color: inherit;
}

.ff-errors {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}
.ff-errors li {
  margin: 0 0 8px;
  padding: 8px 16px;
  text-align: left;
  background: #C32C2A;
  border-left: 4px solid #9E0E0B;
  color: #FFF !important;
}
.ff-errors li a {
  color: inherit;
}