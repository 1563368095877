
/* ---- Field errors ---- */
.field-element.field-element--error  .field-input .textbox,
.field-element.field-element--error  .field-input select {
    background-color: lighten($color-utility-warning, 62%);
    border-color: lighten($color-utility-warning, 58%);
}

.field-element.field-element--warning  .field-input .textbox,
.field-element.field-element--warning  .field-input select {
    background-color: lighten($color-utility-alert, 62%);
    border-color: lighten($color-utility-alert, 58%);
}

.field-error__list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.field-error__list__item {
    padding: 10px 20px;
    margin: 11px 0;
    @include error;
}
