:root {
    --gutter-width: 1rem;
    --outer-margin: 2rem;
    --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
    --half-gutter-width: calc((var(--gutter-width) * 0.5));
}


/* -----------------------------
Layout
----------------------------- */
.freeform-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: var(--gutter-compensation, -0.5rem);
    margin-left: var(--gutter-compensation, -0.5rem);

    @include screen($bp560) {
        flex-wrap: nowrap;
    }

    .freeform-column {
        flex: 1 1 100%;
        padding: 10px 0;
        margin: 0 var(--half-gutter-width, 0.5rem);
        box-sizing: border-box;

        @include screen($bp560) {
            flex: 1 0 auto;
        }
    }

    .freeform-column-only-label {
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }
}


/* -----------------------------
Fields and Labels
----------------------------- */
.freeform-input {
    &[type="text"],
    &[type="number"],
    &[type="email"] {
        @include form-textbox;
        @include form-textbox-styles;
    }

    &[type="checkbox"],
    &[type="radio"] {
        margin-right: 0.5em;
    }
}

select.freeform-input {
    @include form-select;
}

textarea.freeform-input {
    @include form-textbox;
    @include form-textbox-styles;
}

.freeform-label {
    @include field-label;

    &.freeform-input-only-label {
        margin-bottom: 0;
        margin-top: calc(1em + 8px);
    }
}

.freeform-required:after {
    content: "*";
    display: inline-block;
    line-height: inherit;
}

.freeform-instructions {
    font-size: var(--centi-font-size);
    font-family: $primary-font;
    margin-top: 0.25em;
}

.freeform-note {
    color: $color-grey-05;
}

.flatpickr-calendar {
    font-size: var(--centi-font-size);
    font-family: $primary-font;
}


/* -----------------------------
Alignment
----------------------------- */
.freeform-column-content-align-left {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.freeform-column-content-align-right {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.freeform-column-content-align-spread {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.freeform-form .form__element p:last-of-type {
    margin-bottom: 0;
}


/* -----------------------------
Multi page forms
----------------------------- */
.freeform-pages {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;

    li {
        flex: 0 1 100%;
        display: block;
        height: 50px;
        line-height: 50px;
        vertical-align: middle;
        transition: all 0.15s ease-in-out;
        cursor: default;
        text-decoration: none;
        text-align: center;
        transition: all 0.25s;
        border: 0;
        outline: none;
        background-color: $color-white;
        color: $color-grey-06;
        margin-bottom: -1px;
        background-color: $color-white;
        border: 1px solid rgba(0, 0, 0, 0.15);
        text-decoration: none;

        &:first-child {
            border-top-left-radius: .25rem;
            border-top-right-radius: .25rem;

            @include screen($bp992) {
                border-top-left-radius: .25rem;
                border-top-right-radius: initial;
                border-bottom-left-radius: .25rem;
            }
        }

        &:last-child {
            margin-bottom: 0;
            border-bottom-right-radius: .25rem;
            border-bottom-left-radius: .25rem;

            @include screen($bp992) {
                margin-right: 0;
                border-bottom-right-radius: .25rem;
                border-bottom-left-radius: initial;
                border-top-right-radius: .25rem;
            }
        }

        .freeform-pages li.active {
            background-color: $color-primary;
            color: $color-white;
        }
    }
}

// Success
.ff-form-success {
    p {
        @include field-message;
        @include positive;
    }
}

// Errors
.ff-form-errors {
    p {
        @include field-message;
        @include error;
    }
}

.ff-errors {
    @include list-reset;
    margin: 0;

    li {
        @include field-message;
        @include error;
    }
}
