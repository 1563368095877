
.folder-list {
    list-style-type: none;
    padding: 0;
    margin: 0;

    clip: auto;
    height: auto;
    overflow: visible;
    position: relative;
    width: auto;
    animation: fadeInDown 200ms ease;
    animation-fill-mode: forwards;

    &--closed {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    &__spacer {
        min-height: $spacing*2;
    }

    &__item {
        line-height: 1.25;

        &__button {
            all: unset;
            cursor: pointer;
            border: 1px solid $color-grey-02;
            border-right: 1px solid rgba($color-grey-02, 0);
            border-left: 1px solid rgba($color-grey-02, 0);
            margin-top: -1px;

            width: 100%;
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            justify-content: start;
            gap: $spacing*.75;
            padding: $spacing 0;
            transition: all $link-transition;

            .icon {
                width: 16px;
                height: 16px;
                display: inline-grid;
                position: relative;
                transition: all $link-transition;
                left: 0;
            }

            &:hover,
            &:focus,
            &:active {
                border-left: 1px solid rgba($color-grey-02, 1);
                border-right: 1px solid rgba($color-grey-02, 1);
                background-color: $color-grey-01;

                .icon {
                    left: 4px
                }
            }

            &--open {
                .icon {
                    transform: rotate(90deg);
                }
            }
        }

        &__link {
            padding: $spacing $spacing*.5;
            text-decoration: none;
            display: block;
            display: grid;
            grid-auto-flow: column;
            justify-content: start;
            align-items: top;
            gap: $spacing*.75;

            .icon {
                margin-top: $spacing*.25;
            }

            svg path {
                fill: currentColor;
            }

            &:hover,
            &:focus,
            &:active {
                color: $color-secondary;
            }
        }
        &__empty-state {
            padding: $spacing $spacing*.5;
            font-style: italic;
            color: $color-grey-04;
            display: block;
        }
    }

    .folder-list {
        margin-left: $spacing;
        margin-bottom: $spacing;

        @include screen($bp560) {
            margin-left: $spacing*2.5;
        }
    }
}
