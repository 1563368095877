.social-list {
    list-style: none;
    padding: 0;
    margin: 0px;
    margin-left: $spacing--xsmall;
    margin-right: $spacing--xsmall;
    display: grid;
    grid-auto-flow: column;
    gap: $spacing--small;
    justify-content: flex-start;

    &--right {
        justify-content: flex-end;
    }

    &--center {
        justify-content: center;
    }

    &__item {
        display: inline-block;

        a {
            color: inherit;
            display: block;
        }
    }

    svg * {
        fill: currentColor;
    }

    &--primary {
        svg * {
            fill: rgba($color-primary, .7);
        }
    }

    &--circle {
        a {
            display: block;
            display: flex;
            justify-content: center;
            align-items: center;
            width: $spacing*5;
            height: $spacing*5;
            border-radius: 50%;
            padding: $spacing;
            background-color: currentColor;
            transition: background-color $link-transition;
        }

        svg * {
            fill: $color-primary;
        }
    }

    &--circle.social-list--primary {
        a {
            background-color: $color-primary;

            &:hover,
            &:focus,
            &:active {
                background-color: $color-secondary;

            }
        }
        svg * {
            fill: $color-white;
        }
    }



}
