.related-links {
    background-color: $color-alternative;
    @include box-with-grid-deco;
    @include reverse-text;
    margin-bottom: $spacing*3;

    &__title {
        font-size: 1.8rem;
        line-height: 24px;
        font-weight: normal;
        color: $color-secondary;
        margin: 0;
    }

    ul {
        @include list-reset;
        margin: 0;

        li {
            line-height: 1.5;
        }

        a {
            font-family: $accent-font;
            text-decoration: none;

            &:before,
            &:after {
                content: '';

            }



        }

        /* Active / on / current */
        li > a,
        li.current-item > a {
            // color: $color-grey-09;

            &:hover,
            &:focus,
            &:active {
                color: $color-secondary;

            }
        }
    }

    /* - Depth 1 - */
    .depth1 {
        li {
            display: block;
        }

        > li {

            > a {
                text-transform: uppercase;
                font-weight: $fw-bold;
                @include h2($color-white);
                line-height: 1.5;
            }
        }

    }

    /* - Depth 2 - */
    .depth2 {
        margin-left: 0.75em;

        > li {
            margin-top: $spacing;
            margin-bottom: $spacing;

            &:last-child {
                margin-bottom: 0px;
            }

            &:nth-of-type(odd):last-child {
                margin-bottom: 4px;
            }

            a {
                text-transform: none;
                font-weight: normal;
                font-size: 2.2rem;
            }
        }

        .depth2 {
            a {
                font-size: 1.6rem;
            }

        }
    }
}
