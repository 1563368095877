
.share {
    display: flex;
    flex-wrap: wrap;
    background-color: $color-grey-01;
    padding: 20px;
    margin: $spacing*10 0;

    &__title {
        flex: 1 1 auto;
    }

    &__list {
        flex: 0 0 auto;
        @include list-reset;
        display: flex;
        margin-bottom: 0;

        &__item {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 0.5em;

            a {
                color: $color-grey-05;
                text-decoration: none;
                display: block;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover,
                &:focus,
                &:active {
                    color: $color-grey-08;

                    svg {
                        @include svg-contents {
                            fill: $color-grey-08;
                        }
                    }
                }

                svg {
                    vertical-align: middle;
                    margin-left: $spacing*.5;

                    @include svg-contents {
                        transition: fill $link-transition;
                        fill: $color-grey-04;
                    }
                }
            }
        }
    }
}
