
table {
    border-collapse: collapse;
    border: 1px solid $color-grey-05;
    width: 100%;

    .content-block & {
        margin-bottom: $spacing*3;
    }
}

th,
td {
    padding: 5px 10px;
    border: 1px solid $color-grey-05;
}

th,
thead td {
    background: $color-grey-02;
}
