
#wrap {
    @include screen($bpmobile, 'max') {
        overflow-x: hidden;
    }

    // @include screen($bpdesktop) {
    //     padding-top: $header-height;
    // }

    @include screen($bp2400) {
        max-width: $max-page-width;
        margin: 0 auto;
    }
}
