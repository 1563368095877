.breadcrumb {
    list-style: none;
    margin: 1.5rem 0;
    padding: 0;
    font-size: 1.4rem;
    color: #848484;
}

.breadcrumb > li {
    display: inline-block;
}

.breadcrumb > li:before {
    content: "\00a0/\00a0";
}

.breadcrumb > li:first-child:before {
    content: none;
}

@media screen and (max-width: $bp767) {
    .breadcrumb {
        margin-top: 20px;
        margin-bottom: 10px;
        line-height: 1.4;
    }
    .breadcrumb > li {
        display: none;
        margin-left: 0;
    }

    .breadcrumb > li:nth-last-child(2) {
        display: inline-block;
    }
    .breadcrumb > li:nth-last-child(2):before{
        background: none;
        content: '\003c\00a0';
        position: unset;
        font-weight: bold;
        position: relative;
        left: -1px;
        margin-left: 0px;
        top: 0;
    }
}

@media screen and (max-width: $bp560) {
    /* code for the blog posts because the breadcrumbs are nonsensical */
    .blog-post-controller {
        .breadcrumb > li {
            display: none;
            margin-left: 0;
        }
        .breadcrumb > li:nth-last-child(5) {
            display: inline-block;
        }
        .breadcrumb > li:nth-last-child(2) {
            display: none;
        }
        .breadcrumb > li:nth-last-child(5):before {
            background: none;
            content: '\003c\00a0';
            position: unset;
            font-weight: bold;
            position: relative;
            left: -1px;
            margin-left: 0px;
            top: 0;
        }
    }
}
