/* ---- Hidden ---- */
.-hidden {
    display: none !important;
    visibility: hidden;
}

/* ---- Invisible ---- */
.-invisible {
    visibility: hidden;
}

/* ---- Visibility Hidden ---- */
%vis-hidden,
.-vis-hidden {
    @include vis-hidden;
}
