@mixin imageAlignment {
    &.image-full {
        display: block;
        text-align: center;
        float: none;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        background: none;
        padding: 0;
    }

    &.image-right {
        @include screen($bp768) {
            margin-left: calc(var(--body-font-size)*2);
            float: right;
        }
    }

    &.image-left {
        @include screen($bp768) {
            margin-right: calc(var(--body-font-size)*2);
            float: left;
        }
    }

    &.image-center {
        display: block;
        text-align: center;

        img {
            display: block;
            margin-right: auto;
            margin-left: auto;
        }
    }
}

.richtext {
    figure {
        display: inline-block;
        background: none;
        margin: 0 0 var(--body-font-size);
        padding: 0;

        @include imageAlignment;

        &.widget-video {
            display: block;
            padding-bottom: 56.25%;
            margin: 0 0 1em;
        }
    }
}

figure.content-image {
    display: inline-block;
    background: none;
    margin-top: var(--body-font-size);
    margin-bottom: var(--body-font-size);
    margin-left: 0;
    margin-right: 0;
    padding: 0;

    @include imageAlignment;
}



figcaption {
    color: $color-grey-05;
    font-size: var(--milli-font-size);
    margin-top: 0.2em;
    display: block;
}


