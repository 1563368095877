
.block {
    margin-top: 20px;
    margin-bottom: 20px;
}

.block-mb {
    margin-bottom: 20px;
}

.block-large {
    margin-top: 20px;
    margin-bottom: 20px;
}

.block-mb-large {
    margin-bottom: 20px;
}

@include screen($bp360) {
    .block-large {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .block-mb-large {
        margin-bottom: 25px;
    }
}

/* BP small */
@include screen($bp768) {
    .block-large {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .block-mb-large {
        margin-bottom: 30px;
    }
}
