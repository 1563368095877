.widget-video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    clear: both;

    .richtext-video-wrapper & {
        margin-top: $spacing*3;
        margin-bottom: $spacing*3;
    }
}

.widget-video iframe,
.widget-video object,
.widget-video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}

iframe {
    border: 0;
}

.widget-map {
    position: relative;
    padding-bottom: 35%;
    height: 0;
    overflow: hidden;
    clear: both;
    margin-top: $spacing*3;
    margin-bottom: $spacing*3;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
}
