/* ---- Attach wrapper ---- */
.field-element-attach-wrapper {
    position: relative;
}
.field-element-attach-wrapper__button {
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    border-radius: 0;
    background: $color-secondary;
    border: none;
    outline: none;
    z-index: 1;
    cursor: pointer;
    min-width: 45px;
}
.field-element-attach-wrapper__button svg {
    vertical-align: middle;
}
.field-element-attach-wrapper__button:hover,
.field-element-attach-wrapper__button:active,
.field-element-attach-wrapper__button:focus {
    background-color: darken($color-primary, 5%);
}
.field-element-attach-wrapper .field-element .field-input .textbox,
.field-element-attach-wrapper .field-element .field-input select {
    padding-right: 55px;
}
/* White attachment wrapper */
.field-element-attach-wrapper--white .field-element-attach-wrapper__button {
    background-color: $color-grey-02;
}
.field-element-attach-wrapper--white .field-element-attach-wrapper__button:hover,
.field-element-attach-wrapper--white .field-element-attach-wrapper__button:active,
.field-element-attach-wrapper--white .field-element-attach-wrapper__button:focus {
    background-color: $color-grey-03;
}


.field-element-pill-wrapper {
    display: flex;

    &:focus-within {
        outline: $color-grey-06 auto 5px;
    }

    > *:first-child {
        flex: 1 1 auto;
        max-width: 100%;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;

        input,
        select,
        textarea {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;

            &:focus {
                outline: 1px dashed $color-grey-03;
            }
        }
    }

    > *:last-child {
        flex: 0 0 auto;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;

        // offset the field label height
        margin-top: calc( 1em * 1.2 + #{$form-spacing});
    }

    .field-element--hidden-label + input[type="submit"],
    .field-element--hidden-label + button[type="submit"] {
        margin-top: 0;
    }

    input[type="submit"],
    button[type="submit"] {
        @media screen and (max-width: $bp480) {
            padding-left: 1em;
            padding-right: 1em;
        }
    }
}
